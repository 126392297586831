/* eslint-disable */
import axios from "axios";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getCompanyFaqs = async (
  companyName: string,
  companyType: string
) => {
  // get quotes from API /generate3StepQuotes/:companyName/:companyType with axios post call to REACT_APP_NODE_SERVER_URL with companyName and companyType
  const response = await axios.get(
    `${process.env.REACT_APP_NODE_SERVER_URL}/generateCompanyFaqs/${companyName}/${companyType}`
  );
  return response.data;
};

export const extractQuestionAndAnswer = (faqResponse: string) => {
  const faqLines = faqResponse.split("\n");
  const faqLinesFiltered = faqLines.filter((line) => line.trim().length > 0);
  let completeResponse = {};
  faqLinesFiltered.forEach((line, index) => {
    if (line.startsWith("Q:")) {
      const result = line.match(/Q:.*\?/g);
      if (result) {
        const question = result[0].replace("Q:", "");
        completeResponse = { ...completeResponse, question };
      }
    }
    if (line.startsWith("A:")) {
      const result = line.match(/A:.*/g);
      if (result) {
        const answer = result[0].replace("A:", "");
        completeResponse = { ...completeResponse, answer };
      }
    }
  });
  return completeResponse;
};
