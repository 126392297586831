/* eslint-disable */
import { useAtom } from "jotai";
import { IoCloseCircle } from "react-icons/io5";
import { borderedInputWhite, label } from "../../../shared/styles";
import globalState from "../../../store";

interface LocationProps {
  item: string[];
  index: number;
}

const Location: React.FC<LocationProps> = ({ index, item }) => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const { service_area_array, service_areas } = globalStateAtom;

  const handleDelete = (index: any) => {
    const thisService = service_area_array[index];
    const splicedArray = service_area_array.filter(function (item: any) {
      return item !== thisService;
    });
    const splicedAreaArray = service_areas.filter(function (item: any) {
      return item.city !== thisService[0];
    });
    setGlobalState({
      ...globalStateAtom,
      service_areas: splicedAreaArray,
      service_area_array: splicedArray,
    });
  };

  return (
    <div className="flex" id={`${index}`}>
      <div
        onClick={(e) => {
          const confirmation = prompt(
            "Are you sure you want to delete this location?",
            "yes"
          );
          if (confirmation) handleDelete(index);
        }}
        className="absolute transform -translate-x-6 cursor-pointer rounded-full flex p-2"
      >
        <IoCloseCircle color="#E5E7EB" size={30} id={`${index}`} />
      </div>
      <label className={label}>
        <p className="transform translate-x-6">City</p>
        <input
          onChange={(e) => {
            console.log("ON CHANGE");
            const thisServiceArea = [
              e.target.value,
              service_area_array[index][1],
              service_area_array[index][2],
              service_area_array[index][3],
            ];
            const newservice_area_array = [...service_area_array] as any;
            newservice_area_array[index] = thisServiceArea;
            const newservice_areas = [...service_areas];
            newservice_areas[index] = {
              city: thisServiceArea[0],
              state: thisServiceArea[1],
              radius: parseInt(thisServiceArea[2]),
              order: parseInt(thisServiceArea[3]),
            };
            setGlobalState({
              ...globalStateAtom,
              service_areas: newservice_areas,
              service_area_array: newservice_area_array,
            });
          }}
          value={service_area_array[index][0]}
          className={borderedInputWhite}
        />
      </label>
      <label className={`${label} ml-1`}>
        State
        <input
          onChange={(e) => {
            const thisServiceArea = [
              service_area_array[index][0],
              e.target.value,
              service_area_array[index][2],
              service_area_array[index][3],
            ];
            const newservice_area_array = [...service_area_array];
            newservice_area_array[index] = thisServiceArea;
            const newservice_areas = [...service_areas];
            newservice_areas[index] = {
              city: thisServiceArea[0],
              state: thisServiceArea[1],
              radius: parseInt(thisServiceArea[2]),
              order: parseInt(thisServiceArea[3]),
            };
            setGlobalState({
              ...globalStateAtom,
              service_areas: newservice_areas,
              service_area_array: newservice_area_array,
            });
          }}
          value={service_area_array[index][1]}
          className={borderedInputWhite}
        />
      </label>
      <label className={`${label} ml-1`}>
        Radius
        <input
          onChange={(e) => {
            const thisServiceArea = [
              service_area_array[index][0],
              service_area_array[index][1],
              e.target.value,
              service_area_array[index][3],
            ];
            const newservice_area_array = [...service_area_array];
            newservice_area_array[index] = thisServiceArea;
            const newservice_areas = [...service_areas];
            newservice_areas[index] = {
              city: thisServiceArea[0],
              state: thisServiceArea[1],
              radius: parseInt(thisServiceArea[2]),
              order: parseInt(thisServiceArea[3]),
            };

            setGlobalState({
              ...globalStateAtom,
              service_areas: newservice_areas,
              service_area_array: newservice_area_array,
            });
          }}
          value={service_area_array[index][2]}
          className={borderedInputWhite}
        />
      </label>
      <label className={`${label} ml-1`}>
        order - {service_area_array[index][3]}
      </label>
    </div>
  );
};

export default Location;
