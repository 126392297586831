/* eslint-disable */
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useAtom } from "jotai";
import globalState from "../store";
import {
  borderedInputWhite,
  borderedInputWhiteWrap,
  button,
  containerCentered,
  input,
  inputWhite,
} from "./styles";

import { industry_types } from "../data/industry_types";
import { faqs } from "../data/faqs";
import { color_schemes } from "../data/color_schemes";

const Admin: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [repos, setRepos] = useState([]);
  const [slug, setSlug] = useState("");
  const [fetchedLpData, setFetchedLpData] = useState([]);
  const [batchSlugs, setBatchSlugs] = useState("");
  const [createdSlugs, setCreatedSlugs] = useState([] as any[]);
  const [showIndustries, setShowIndustries] = useState(false);
  const industryHash = {
    0: {},
    1: {},
  };
  const handleChange = (e) => {
    setSlug(e.target.value);
  };

  const fetchRepos = async () => {
    try {
      const fetchedDataResponse = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/get_repos`
      );
      const fetchedData = fetchedDataResponse.data;
      const curRepos = fetchedData.limit_repos;
      // const curRepos = [{slug:"noooo",network_status:"gobucks",last_published:"yesterday"}]
      // console.log('curRepos', curRepos)
      setRepos(curRepos);
      // console.log('fetchedDat', fetchedData)
    } catch (error) {
      const errorObject = error as any;
      const errorMessage = errorObject?.data?.message;
      // console.log("error", error);
      // console.log("errorMessage", errorMessage);
    }
  };

  const fetchLandingPageActionEvents = async () => {
    event.preventDefault();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/get_landing_page_action_events/${slug}`
      );
      const responseMessage = response.data.message;
      const responseData = response.data.events;
      if (responseMessage === "success") {
        setFetchedLpData(responseData);
      } else {
        setFetchedLpData(responseMessage);
      }
      // console.log("responseMessage", responseMessage)
      // console.log("responseData", responseData)
    } catch (error) {
      const errorObject = error as any;
      const errorMessage = errorObject?.data?.message;
      // console.log("error", error);
      // console.log("errorMessage", errorMessage);
    }
  };
  const getLandingPageBlankObject = (facebookUrl: any) => {
    return {
      sitePk: 0 as number,
      id_key: "",
      cover_upload: "",
      multipage: true,
      company_name: "",
      company_tagline: "",
      company_type: "",
      slug: "", // turn this into a ternery like so: route.params.slug ? route.params.slug : ''
      fbSlug: facebookUrl,
      hero_gallery_photos: [],
      hero_image: "",
      // "hero_image": [],
      // "hero_type": '',
      // "unsplash": '',
      logo: "",
      custom_primary: "",
      // "shade":  '',
      custom_secondary: "",
      // "secondary_shade":  '',
      phone: "",
      email: "",
      license_number: "",
      insured: false,
      bonded: false,
      emergency: false,
      veteran: false,
      family: false,
      local: false,
      about: "",
      about_us_image: "",
      // "about_us_type": '',
      twitter: "",
      instagram: "",
      facebook: "",
      google: "",
      // "snapchat": '',
      youtube: "",
      // "linkedin": '',
      nextdoor: "",
      tiktok: "",
      // "etsy": '',
      yelp: "",
      // "thumbtack": '',
      // "featured_video": '',
      street_address: "",
      city: "",
      state: "",
      zip_code: "",
      country: "",
      services: [],
      testimonials: [],
      faqs: [],
      gallery: [],
      gallery_names: [],
      // "product": [],
      // "awards": [],
      about_header: "",
      service_header: "",
      service_subtitle: "",
      work_header: "",
      work_subtitle: "",
      partners_header: "",
      product_header: "",
      product_subtitle: "",
      // "award_header":  '',
      testimonial_header: "",
      testimonial_subtitle: "",
      paid_url: "",
      github_repo: "",
      external_link: "",
      // "company_type":  '',
      business_hours: [],
      service_area_array: [],
      service_areas: [],
      // "galleries": []
      // "fbData": {} as any,
      galleries: [],
      fbData: {
        about: "",
        address: "",
        company_name: "",
        company_type: "",
        logo: "",
        phone: "",
        extraPhotos: [],
        currentPulledPhotoCount: 0,
        photos: [],
        services: [],
        socialMediaUrls: "",
        testimonials: [],
      },
      review_link: "",
      leave_review_link_header: "",
      review_link_more: "",
      see_more_reviews_header: "",
      alternative_review_html: "",
      cta_main: "Text",
      cta_main_1: "Call",
      cta_nav: "Link",
      cta_slide_in: "Email",
      cta_main_link: "",
      cta_main_1_link: "",
      cta_nav_link: "",
      cta_slide_in_link: "",
      call_to_action_message: "Send us a Text",
      call_to_action_message_main_1: "Give us a Call",
      call_to_action_message_nav: "Book Now",
      call_to_action_message_slide_in: "Email Us",
      home_nav_title: "Home",
      service_nav_title: "Services",
      gallery_nav_title: "Galleries",
      reviews_nav_title: "Reviews",
      gallery_all_title: "All Photos",
      socials_header: "",
      three_step_header: "",
      three_step_subtitle: "",
      three_step_1_header: "",
      three_step_1_subtitle: "",
      three_step_2_header: "",
      three_step_2_subtitle: "",
      three_step_3_header: "",
      three_step_3_subtitle: "",
      // partners_header:'',
      payment_header: "",
      payment_subtitle: "",
      faq_header: "",
      team_header: "",
      team_subtitle: "",
      team_nav_title: "Team",
      jobs_header: "",
      jobs_subtitle: "",
      jobs_nav_title: "Jobs",
      cta_header: "",
      cta_subtitle: "",
      final_cta_header: "",
      final_cta_subtitle: "",
      call_to_action_message_nav_1: "",
      call_to_action_message_recruiting: "",
      cta_nav_1: "",
      cta_recruiting: "",
      cta_nav_1_link: "",
      cta_recruiting_link: "",
      cta_get_a_quote: "",
      cta_get_a_quote_link: "",
      call_to_action_message_get_a_quote: "",
      payments: [],
      awards: [],
      deal_header: "",
      deal_subtitle: "",
      deal_button_text: "",
      deal_button_link: "",
      teammates: [],
      team_col_1_header: "",
      team_col_1: "",
      team_col_2_header: "",
      team_col_2: "",
      black_owned: false,
      native_owned: false,
      woman_owned: false,
      latino_owned: false,
      bbb_attribute: false,
      leave_review_link_3_header: "",
      leave_review_link_3: "",
      thumbtack: "",
      bbb: "",
      homeadvisor: "",
      find_header: "",
      jobs_description: "",
      // alternative_review_js: '',
      years_in_business_attribute: 0,
      products: [],
      version: "",
      show_jobs_page: false,
      show_team_page: false,
      team_show_image: true,
      text_show_address: true,
      text_show_attachment: false,
      text_show_email: true,
      text_show_full_name: true,
      text_show_message: true,
      text_message_placeholder: "How can we help?",
      text_show_phone: true,
      text_show_promo_code: false,
      text_show_requested_date: true,
      text_show_services: true,
      prophone_contact_id: "",
      salesloft_id: "",
      selected_plan: "",
      service_packages_array: [],
    };
  };
  const batchCreateSites = async () => {
    setLoading(true);

    const batchedSlugs = [];
    const batchedSlugsSplit = batchSlugs.split(",");

    for (let i = 0; i < batchedSlugsSplit.length; i++) {
      const createSiteData = {
        fbSlug: batchedSlugsSplit[i].trim().split(" ")[0],
        industry: batchedSlugsSplit[i].trim().split(" ")[1],
      };

      const createResponse = await axios.post(
        `${process.env.REACT_APP_NODE_SERVER_URL}/createSiteEndpoint`,
        createSiteData
      );
      let landingPageData = getLandingPageBlankObject(createSiteData.fbSlug);

      const { pythonCreateStatus, sitePk, siteSlug } = createResponse.data;
      let { fbData } = createResponse.data;

      landingPageData = {
        ...landingPageData,
        ...createResponse.data.completedData,
      };

      if (pythonCreateStatus === 500) {
      } else if (pythonCreateStatus === 201 && siteSlug !== undefined) {
        landingPageData.sitePk = sitePk;

        const fbPhotoCount = fbData?.photos?.length;

        landingPageData.fbData = {
          ...landingPageData.fbData,
          ...fbData,
        };

        fbData = landingPageData.fbData;

        landingPageData.gallery =
          fbData &&
          fbData.photos &&
          fbData?.photos.map((fbPhoto: any, idx: number) => {
            return {
              alt_tag: idx,

              archived: false,
              associated_service: "idx",
              gallery: null,
              photo: fbPhoto.imgSrc,
              source: "facebook",
              largeImageHref: fbPhoto.largeImageHref,
              landing_page: sitePk,
              order: idx,
              uncropped_gallery_photo: null,
              gallery_name: "",
              selected: false,
              saved: false,
            };
          });
      }
      const saveResponse = await handleBatchSave(landingPageData);

      batchedSlugs.push(createResponse.data.siteSlug);
    }
    setCreatedSlugs(batchedSlugs);
    setLoading(false);
  };
  const handleBatchSave = async (landingPageData: any) => {
    try {
      const services = landingPageData.services;
      const handleSavingServicePhotosResponse = await axios.post(
        `${process.env.REACT_APP_NODE_SERVER_URL}/handleSavingServicePhotosEndpoint`,
        { services: services }
      );
      const handledServices = handleSavingServicePhotosResponse.data;
      const saveLandingPageDataResponse = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/edit/landingpage`,
        { ...landingPageData, services: handledServices }
      );
      const pk =
        saveLandingPageDataResponse && saveLandingPageDataResponse.data
          ? saveLandingPageDataResponse.data.pk
          : "";

      const tmpPhotos = landingPageData.gallery as any[]; // getPhotos()
      const heroPhotos = landingPageData.hero_gallery_photos as any[]; // getPhotos()
      const photos = tmpPhotos; // .slice(0,2)
      // console.log("globalStateAtom.sitePk", landingPageData.sitePk);
      // console.log("globalStateAtom REAL PK ", pk);
      // console.log("photos", photos);

      // setSavingStatus("Saving - Gallery Photos");
      // Batch all photo saving items together
      const handleSavingResponse = await axios.post(
        `${process.env.REACT_APP_NODE_SERVER_URL}/handleSavingPhotosEndpoint`,
        { pk: landingPageData.sitePk, photos }
      );
      // console.log("handleSavingResponse", handleSavingResponse);
      // console.log("handleSavingResponse", handleSavingResponse.data);
      if (handleSavingResponse?.data?.status === "Success") {
        // console.log(
        //   " gallery PHOTOS SAVE SUCCESS - ",
        //   handleSavingResponse.data.photos
        // );

        landingPageData.gallery = handleSavingResponse.data.photos;
        // setGlobalState({...globalStateAtom, gallery:handleSavingResponse.data.photos})
      } else {
        throw new Error("Error on saving gallery photos");
      }
      // const handleSavingHeroPhotosResponse =  await axios.post(`${process.env.REACT_APP_NODE_SERVER_URL}/handleSavingPhotosEndpoint`, {heroPhotos: true, pk:globalStateAtom.sitePk, photos})
      // setSavingStatus("Saving - Hero Photos");
      const handleSavingHeroPhotosResponse = await axios.post(
        `${process.env.REACT_APP_NODE_SERVER_URL}/handleSavingPhotosEndpoint`,
        {
          heroPhotos: true,
          pk: landingPageData.sitePk,
          photos: heroPhotos,
        }
      );
      if (handleSavingHeroPhotosResponse?.data?.status === "Success") {
        // console.log(
        //   " HERO PHOTOS SAVE SUCCESS - ",
        //   handleSavingHeroPhotosResponse.data.photos
        // );

        landingPageData.hero_gallery_photos =
          handleSavingHeroPhotosResponse.data.photos;
        // setGlobalState({...globalStateAtom, hero_gallery_photos:handleSavingResponse.data.photos})
      } else {
        throw new Error("Error on saving cover photos");
      }
      // // console.log(
      //   "handleSavingHeroPhotosResponse",
      //   handleSavingHeroPhotosResponse
      // );
      // console.log(
      //   "handleSavingHeroPhotosResponse",
      //   handleSavingHeroPhotosResponse.data
      // );
      // setGlobalState(globalStateAtom);
      // alert(`Saved successfully`);
      // await fetchAllData();

      // setSavingStatus("");
      // setLoading(false);
    } catch (e) {
      const errorResponse = e as any;
      // console.log("e", errorResponse);
      // console.log("e", errorResponse.response);
      // // console.log('e',errorResponse.response.data.message)
      // // console.log('e',errorResponse.response.message)
      const errorMessage = errorResponse?.response?.data?.message;
      // console.log(e, "saving error");

      // alert(
      //   `There was a problem saving. Nick and Shannon broke it. Here is how:  ${
      //     errorMessage || errorResponse
      //   }`
      // );
      // setSavingStatus("");
      // setLoading(false);
    }
  };
  // console.log("hello");

  return (
    <div className="h-full">
      <div style={{ border: "10px solid black", backgroundColor: "green" }}>
        <h2 className="text-3xl">Batch create sites</h2>
        Comma separated slugs.
        <br /> {batchSlugs}
        <br />
        <textarea
          placeholder="ozarksdetail auto-detailing-base, bamlandscaping landscape-hardscape-base"
          value={batchSlugs}
          className={input}
          onChange={(e) => {
            setBatchSlugs(e.target.value);
          }}
          autoFocus
        />
        <br />
        <button
          className={
            "p-3 px-6 w-24 rounded-full border-2 text-white text-sm bg-gray-500"
          }
          style={{ backgroundColor: "purple" }}
          onClick={batchCreateSites}
        >
          Create Sites
        </button>
        <br />
        {loading
          ? "I AM LOADING I AM LOADING I AM LOADING I AM LOADING"
          : "not loading"}
        <br />
        <button
          style={{ backgroundColor: "red" }}
          className={
            "p-3 px-6 w-24 rounded-full border-2 text-white text-sm bg-gray-500"
          }
          onClick={() => {
            setShowIndustries(!showIndustries);
          }}
        >
          {!showIndustries ? "Show Industries" : "Hide Industries"}
        </button>
        <br />
        {showIndustries ? (
          <div>
            {" "}
            industry options
            <div>
              {" "}
              key to include: detailing-base - proper name : Auto Detailing
            </div>
            <div>
              {" "}
              key to include: automotive-repair-base - proper name : Automotive
              Repair Shop
            </div>
            <div> key to include: cleaning-base - proper name : Cleaning</div>
            <div>
              {" "}
              key to include: construction-handyman-base - proper name :
              Construction/Remodeling
            </div>
            <div>
              {" "}
              key to include: garage-door-base - proper name : Garage Door
              Technicians
            </div>
            <div>
              {" "}
              key to include: in-home-base - proper name : In-Home Service
            </div>
            <div>
              {" "}
              key to include: irrigation-base - proper name : Irrigation
              Specialists
            </div>
            <div>
              {" "}
              key to include: junk-removal-base - proper name : Junk Removal
            </div>
            <div>
              {" "}
              key to include: landscape-hardscape-base - proper name :
              Landscaping/Hardscaping
            </div>
            <div>
              {" "}
              key to include: landscape-snow-base - proper name : Landscaping &
              Snow Removal
            </div>
            <div> key to include: lawncare-base - proper name : Lawn Care</div>
            <div>
              {" "}
              key to include: painting-renovation-base - proper name : Painting,
              Staining, & Home Renovations
            </div>
            <div> key to include: painting-base - proper name : Painting</div>
            <div>
              {" "}
              key to include: pressure-washing-base - proper name : Power
              Washing
            </div>
            <div>
              {" "}
              key to include: property-management-base - proper name : Property
              Management Company
            </div>
            <div>
              {" "}
              key to include: cleaning-residential-base - proper name :
              Residential Cleaning
            </div>
            <div> key to include: tree-base - proper name : Tree Services</div>
            <div> key to include: base - proper name : Other</div>
          </div>
        ) : (
          <></>
        )}
        <div style={{ backgroundColor: "pink" }}>
          Created sites
          <br />
          {createdSlugs.map((slug) => {
            return "slug: " + slug + "\n";
          })}
        </div>
      </div>
    </div>
  );
};

export default Admin;
