/* eslint-disable */
/* eslint-disable prettier/prettier */
import { useState, useEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useAtom } from "jotai";
import globalState from "../store";
import {
  borderedInputWhite,
  borderedInputWhiteWrap,
  button,
  containerCentered,
  input,
  inputWhite,
} from "./styles";
import formatLandingPage from "../utils/helpers/formatLandingPage";

import { industry_types } from "../data/industry_types";
import { faqs } from "../data/faqs";
import { color_schemes } from "../data/color_schemes";
import ReactJson from "react-json-view";
import BasicInfoPage from "../pages/basicInfo";
import CustomPage from "../pages/custom";
import DomainPage from "../pages/domain";
import PartnershipsPage from "../pages/partnerships";
import PhotosPage from "../pages/photos";
import ReviewsPage from "../pages/reviews";
import ServicesPage from "../pages/services";
import TeamPage from "../pages/team";
import FAQsPage from "../pages/faqs";
import Nav from "./Nav";
import Logo from "../pages/logo";

const NewSiteTwo: React.FC = () => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const [facebookUrl, setFacebookUrl] = useState("");
  const [industry, setIndustry] = useState("");
  const history = useHistory();
  const [returnedSlug, setReturnedSlug] = useState("");
  const [startedBuilding, setStartedBuilding] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentSlug, setCurrentSlug] = useState("" as string);
  const [fbData, setFbData] = useState({
    info: {},
    photos: [],
    services: [],
    reviews: [],
  });
  const [leads, setLeads] = useState({} as any);
  const [landingPages, setLandingPages] = useState({} as any);

  const [showJsonFbData, setShowJsonFbData] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [showLeads, setShowLeads] = useState(true);
  const [showLandingPages, setShowLandingPages] = useState(true);
  const [showLimited, setShowLimited] = useState(false);
  const showLimitMax = 4;
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [showHash, setShowHash] = useState({
    logo: true,
    basic_info: true,
    services: true,
    photos: true,
    faq: true,
    partnerships: true,
    reviews: true,
    faqs: true,
    team: true,
    domain: true,
    custom: true,
    FRPT: true,
  });

  const [newLead, setNewlead] = useState({
    additional_info: "",
    assigned_to: "Smiley",
    business_facebook_slug: "",
    channel: "Email",
    date_submitted: "",
    facebook_slug: "",
    internet_status: "",
    location: "",
    link_to_editor: "",
    link_to_sample: "",
    owner: "Nick Ornitz",
    personal: "",
    priority: "Low",
    services: "",
    site_slug: "",
    status: "",
    type: "",
    industry: "",
    website_review_comments: "",
  } as any);

  useEffect(() => {
    setGlobalState({ ...globalStateAtom, slug: returnedSlug });
  }, [returnedSlug]);
  const clickSlug = async (site_slug: any) => {
    setShowEditor(false);
    console.log("clickclug", site_slug);
    await fetchAllData(site_slug);
    // console.log('past fetch',globalStateAtom)
    setShowEditor(true);
  };
  const setCurrentEditLead = async (lead: any) => {
    // console.log('setCurrentEditLead ')
    setNewlead(lead);
  };
  const handleSave = async () => {
    try {
      // console.log("gobucks",globalStateAtom);
      setLoading(true);
      // setSavingStatus("Saving - Service Photos");
      const handleSavingServicePhotosResponse = await axios.post(
        `${process.env.REACT_APP_NODE_SERVER_URL}/handleSavingServicePhotosEndpoint`,
        { services: globalStateAtom.services }
      );
      const handledServices = handleSavingServicePhotosResponse.data;

      globalStateAtom.services = handledServices;
      // const handleSavingServicePhotosResponse =  await axios.post(`${process.env.REACT_APP_NODE_SERVER_URL}/handleSavingServicePhotosEndpoint`, {heroPhotos: true, pk:globalStateAtom.sitePk, photos: servic})
      // setSavingStatus("Saving - LandingPage");
      const saveLandingPageDataResponse = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/edit/landingpage`,
        { ...globalStateAtom, services: handledServices }
      );
      const pk =
        saveLandingPageDataResponse && saveLandingPageDataResponse.data
          ? saveLandingPageDataResponse.data.pk
          : "";

      const tmpPhotos = globalStateAtom.gallery as any[]; // getPhotos()
      const heroPhotos = globalStateAtom.hero_gallery_photos as any[]; // getPhotos()
      const photos = tmpPhotos; // .slice(0,2)
      // console.log("globalStateAtom.sitePk", globalStateAtom.sitePk);
      // console.log("globalStateAtom REAL PK ", pk);
      // console.log("photos", photos);

      // setSavingStatus("Saving - Gallery Photos");
      // Batch all photo saving items together
      const handleSavingResponse = await axios.post(
        `${process.env.REACT_APP_NODE_SERVER_URL}/handleSavingPhotosEndpoint`,
        { pk: globalStateAtom.sitePk, photos }
      );
      // console.log("handleSavingResponse", handleSavingResponse);
      // console.log("handleSavingResponse", handleSavingResponse.data);
      if (handleSavingResponse?.data?.status === "Success") {
        globalStateAtom.gallery = handleSavingResponse.data.photos;
        // setGlobalState({...globalStateAtom, gallery:handleSavingResponse.data.photos})
      } else {
        throw new Error("Error on saving gallery photos");
      }
      // const handleSavingHeroPhotosResponse =  await axios.post(`${process.env.REACT_APP_NODE_SERVER_URL}/handleSavingPhotosEndpoint`, {heroPhotos: true, pk:globalStateAtom.sitePk, photos})
      // setSavingStatus("Saving - Hero Photos");
      const handleSavingHeroPhotosResponse = await axios.post(
        `${process.env.REACT_APP_NODE_SERVER_URL}/handleSavingPhotosEndpoint`,
        {
          heroPhotos: true,
          pk: globalStateAtom.sitePk,
          photos: heroPhotos,
        }
      );
      if (handleSavingHeroPhotosResponse?.data?.status === "Success") {
        globalStateAtom.hero_gallery_photos =
          handleSavingHeroPhotosResponse.data.photos;
        // setGlobalState({...globalStateAtom, hero_gallery_photos:handleSavingResponse.data.photos})
      } else {
        throw new Error("Error on saving cover photos");
      }

      const oldCache =
        JSON.parse(localStorage.getItem("globalState") || "") || ({} as any);
      const newCache = oldCache as any;
      // console.log('oldcache',oldCache)
      const newLandingPageCacheItem = {
        ...globalStateAtom,
      };
      // console.log('currentSlug',globalStateAtom)
      // console.log('currentSlug',globalStateAtom.slug)
      // // console.log('currentSlug',currentSlug)
      // console.log('newLandingPageCacheItem',newLandingPageCacheItem)

      newCache[globalStateAtom.slug] = newLandingPageCacheItem;
      // console.log('newCache',newCache)

      localStorage.setItem("globalState", JSON.stringify(newCache));
      setGlobalState(globalStateAtom);
      alert(`Saved successfully`);
      await fetchAllData();

      // setSavingStatus("");
      setLoading(false);
    } catch (e) {
      const errorResponse = e as any;
      // console.log("e", errorResponse);
      // console.log("e", errorResponse.response);
      // // console.log('e',errorResponse.response.data.message)
      // // console.log('e',errorResponse.response.message)
      const errorMessage = errorResponse?.response?.data?.message;
      // console.log(e, "saving error");
      alert(
        `There was a problem saving. Nick and Shannon broke it. Here is how:  ${
          errorMessage || errorResponse
        }`
      );
      // setSavingStatus("");
      setLoading(false);
    }
  };
  const fetchAllData = async (currentSlug: string) => {
    // console.log('fetching ')
    if (history.location.pathname === `/services/${currentSlug}`)
      history.push(`/preview/${currentSlug}`);
    try {
      // console.log('currentslug',currentSlug)
      if (currentSlug && currentSlug != "") {
        const localStorageGet = localStorage.getItem("globalState") || "";
        // console.log('localStorageGet',localStorageGet)
        let localStorageCache;

        if (localStorageGet != "") {
          localStorageCache = JSON.parse(localStorageGet) || ({} as any);
        } else {
          localStorageCache = {};
        }
        // console.log('localStorageCache',localStorageCache)
        if (localStorageCache != "" && localStorageCache[currentSlug]) {
          // console.log('GLOBAL STATE NOT NULL localStorageCache[currentSlug as any]',localStorageCache[currentSlug])
          // console.log('GLOBAL STATE NOT NULL',typeof localStorage.getItem("globalState") )
          // console.log('GLOBAL STATE NOT NULL', localStorageCache[currentSlug])
          // console.log('GLOBAL STATE NOT NULL', localStorageCache[currentSlug].company_name)

          setGlobalState(localStorageCache[currentSlug]);
        } else {
          console.log("currentSlug", currentSlug);

          // setFetching(true);
          const fetchedDataResponse = await axios.get(
            `${process.env.REACT_APP_SERVER_URL}/api/getLandingPage/${currentSlug}`
          );
          const fetchedData = fetchedDataResponse.data.message;
          console.log("fetchedData", fetchedData);
          const formattedData = await formatLandingPage(fetchedData);

          // Gallery pulls from globalStateAtom -
          // This will hold the fbPhotos if just came from

          const { gallery } = globalStateAtom;

          if (
            globalStateAtom.fbData &&
            globalStateAtom.fbData.photos &&
            globalStateAtom.fbData.photos.length > 0
          ) {
            // coming from createSite
            const { fbSlug } = globalStateAtom;
            const { services } = globalStateAtom;
            const numFbPhotos = globalStateAtom.fbData.photos.length;
            const fbData = {
              ...globalStateAtom.fbData,
              currentPulledPhotoCount: numFbPhotos,
            };
            // const fbData = {...globalStateAtom.fbData, currentPulledPhotoCount: numFbPhotos, photos:[]}

            // const oldCache = JSON.parse(localStorage.getItem('globalState') || "") || {} as any
            const newCache = localStorageCache as any;
            // console.log('oldcache localStorageCache',localStorageCache)
            const newLandingPageCacheItem = {
              ...formattedData,
              services,
              gallery,
              fbSlug,
              fbData,
              multipage:
                "multipage" in formattedData ? formattedData.multipage : true,
            };
            // console.log('currentSlug',currentSlug)
            // console.log('currentSlug',currentSlug)
            // console.log('newLandingPageCacheItem',newLandingPageCacheItem)

            newCache[currentSlug] = newLandingPageCacheItem;
            // console.log('newCache',newCache)

            localStorage.setItem("globalState", JSON.stringify(newCache));
            setGlobalState({
              ...formattedData,
              services,
              gallery,
              fbSlug,
              fbData,
              multipage:
                "multipage" in formattedData ? formattedData.multipage : true,
            });
          } else {
            const oldCache = localStorageCache; // JSON.parse(localStorage.getItem('globalState') ||"") || {} as any
            const newCache = oldCache as any;
            console.log("oldcache", oldCache);
            const newLandingPageCacheItem = {
              ...formattedData,
              // services,
              gallery,
              // fbSlug,
              fbData,
              multipage:
                "multipage" in formattedData ? formattedData.multipage : true,
            };
            // console.log('currentSlug',currentSlug)
            // console.log('newLandingPageCacheItem',newLandingPageCacheItem)

            newCache[currentSlug] = newLandingPageCacheItem;
            // console.log('newCache',newCache)

            localStorage.setItem("globalState", JSON.stringify(newCache));
            // coming from having just saved
            // console.log("no fb data hotos", formattedData);
            setGlobalState({
              ...formattedData,
              multipage:
                "multipage" in formattedData ? formattedData.multipage : true,
            });
          }
        }
        // setMounted(true);
        // setFetching(false);
        // if (history.location.pathname === `/preview/${slug}`) history.goBack();
      }
    } catch (error) {
      const errorObject = error as any;
      const errorMessage = errorObject?.data?.message;
      // console.log("error", error);
      // console.log("errorMessage", errorMessage);
    }
  };
  console.log("what is globalState in Domain Info", globalState);
  const handleMakeNewTwo = async () => {
    // console.log('getfbDAta')
    const createSiteData = {
      fbSlug: facebookUrl,
      industry,
    };
    // console.log('createSiteData',createSiteData)
    const getFbDataResponse = await axios.get(
      `${process.env.REACT_APP_NODE_SERVER_URL}/getFacebookDataEndpoint/${facebookUrl}`
    );
    const fbDataFound = getFbDataResponse.data as any;
    setFbData(fbDataFound);
    // console.log('fbData',fbData)
  };
  // const getAndSaveFacebookData = async () => {

  // }
  const getFacebookData = async (facebook_slug: string) => {
    // get fb data from table
    try {
      const getFbDataFromDBResponse = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/get_facebook_data/${facebook_slug}`
      );
      const getFbDataFromDB = getFbDataFromDBResponse.data;
      // if doesn't exist
      console.log("getFbDataFromDB.", getFbDataFromDB);
      if (getFbDataFromDB.found == false) {
        try {
          try {
            // console.log('newLead.',newLead.facebook_slug)
            const getFbDataResponse = await axios.get(
              `${process.env.REACT_APP_NODE_SERVER_URL}/getFacebookDataEndpoint/${facebook_slug}`
            );
            // console.log('getFbDataResponse.datax',getFbDataResponse)
            // console.log('getFbDataResponse.dataxy',getFbDataResponse.data)
            const curFbDatax = getFbDataResponse.data;
            // }
            try {
              const saveFbDataResponse = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/api/save_facebook_data`,
                {
                  facebook_slug: newLead.facebook_slug,
                  facebook_data: curFbDatax,
                }
              );
              const saveFbData = saveFbDataResponse;
            } catch (e) {
              console.log("failed save fb", e);
            }
            console.log("curfbdatax", curFbDatax);
            console.log("curfbdatax", curFbDatax);

            if (
              getFbDataFromDB &&
              getFbDataFromDB.facebook_data &&
              getFbDataFromDB.facebook_data.length > 0
            ) {
              // if(getFbDataFromDB && getFbDataFromDB.length > 0){
              return getFbDataFromDB.facebook_data[0];
            }
            return curFbDatax;
          } catch (e) {
            console.log("e", e);
            return {};
          }
        } catch (e) {
          console.log("error get save fb", e);
          return {};
        }
        // console.log('savefbdata',saveFbData)
      } else {
        console.log("great successssssssss", getFbDataFromDB);
        if (
          getFbDataFromDB &&
          getFbDataFromDB.facebook_data &&
          getFbDataFromDB.facebook_data.length > 0
        ) {
          // if(getFbDataFromDB && getFbDataFromDB.length > 0){
          return getFbDataFromDB.facebook_data[0];
        }
        console.log("great successaaa", getFbDataFromDB);
        return getFbDataFromDB;
      }
      // const getFbDataFromD B = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/get_fb_data/${facebook_slug}`);
    } catch (e) {
      console.log("error get facebookdata", e);
    }
  };

  const getLeads = async () => {
    const getLeadsResponse = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/get_leads`
    );
    const leadsData = getLeadsResponse.data.message as any;
    const leadsFbSlugMap = {} as any;
    leadsData.forEach((ld: any) => {
      if (!leadsFbSlugMap[ld.facebook_slug])
        leadsFbSlugMap[ld.facebook_slug] = ld;
    });
    //  = leadsData.map((ld)=>{

    //   return ld
    // })
    // console.log('leadsFbSlugMap',leadsFbSlugMap)
    // console.log('leadsData',leadsData)
    setLeads(leadsFbSlugMap);
    // console.log('getLeads',getLeadsResponse)
  };
  const getLandingPages = async () => {
    const getLandingPagesResponse = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/get_landing_pages`
    );
    const getLandingPagesData = getLandingPagesResponse.data.message as any;
    setLandingPages(getLandingPagesData);
    // console.log('getLandingPagesData',getLandingPagesData)
  };
  const editLead = async () => {
    const editLeadResponse = await editLeadPython(newLead);
    // console.log('editlead needs implementing',editLeadResponse)
    if (newLead && newLead.facebook_slug != "") {
      leads[newLead.facebook_slug] = newLead;
      // console.log('leadssss',leads)
      // console.log('newLead',newLead)
      setLeads(leads);
      forceUpdate();
    }
  };
  // full run
  const addLeadAndCreateAndSaveSite = async () => {
    console.log("addLeadAndCreateSite");
    try {
      setLoading(true);
      const pythonLead = await addLeadPython();
      const newLeads = leads;
      const fbSlug = newLead.facebook_slug;
      newLeads[fbSlug] = pythonLead;
      if (!newLeads[fbSlug]["lead_type"] && newLeads[fbSlug]["industry"])
        newLeads[fbSlug]["lead_type"] = newLeads[fbSlug]["industry"];
      if (!newLeads[fbSlug]["lead_type"] && !newLeads[fbSlug]["industry"])
        newLeads[fbSlug]["lead_type"] = "";
      setLeads(newLeads);
      if (newLead.facebook_slug != "") {
        const curFbDataResponse = await getFacebookData(newLead.facebook_slug);
        const curFbData = curFbDataResponse;
        // const curFbData = curFbDataResponse.found == true ? curFbDataResponse.facebook_data : null
        console.log("pythonCreateStatus.found", curFbDataResponse.found);
        console.log("pythonCreateStatus", curFbDataResponse);
        console.log("curFbData", curFbData);
        setFbData({ ...curFbData });
        newLeads[fbSlug]["fb_data"] = curFbData;

        setLeads(newLeads);
        console.log("curFbData", curFbData);

        const createLandingPageData = (await createLandingPage(
          curFbData
        )) as any;
        newLeads[fbSlug]["link_to_editor"] =
          "https://int.prophone.com/logo/" + createLandingPageData.siteSlug;
        setLeads(newLeads);
        let landingPageData = getLandingPageBlankObject(fbSlug) as any;
        const {
          completedData,
          pythonCreateStatus,
          pythonCreateMessage,
          sitePk,
          siteSlug,
          siteData,
        } = createLandingPageData;

        landingPageData = {
          ...landingPageData,
          ...createLandingPageData.completedData,
        };
        if (pythonCreateStatus === 500) {
          console.log("pythonCreateStatus");
        } else if (pythonCreateStatus === 201 && siteSlug !== undefined) {
          landingPageData.sitePk = sitePk;
          landingPageData.siteSlug = siteSlug;
          newLeads[fbSlug]["site_slug"] = siteSlug;
          console.log(pythonLead, "site_slug", siteSlug);
          console.log(newLeads[fbSlug], "site_slug", siteSlug);
          await editLeadPython(newLeads[fbSlug]);
          landingPageData.services =
            landingPageData &&
            landingPageData.services.map((service: any) => {
              if (!service["service-description-long"])
                service["service-description-long"] = "";
              if (!service["service-description-long-pt-1"])
                service["service-description-long-pt-2"] = "";
              if (!service["service-description-long-pt-2"])
                service["service-description-long-pt-2"] = "";
              return service;
            });
          console.log(landingPageData, "landingPageData");
          console.log(fbData, "fbData landingPageData");
          console.log(curFbData, "fbData landingPageData");

          landingPageData.gallery =
            curFbData &&
            curFbData.photos &&
            curFbData?.photos.map((fbPhoto: any, idx: number) => {
              return {
                alt_tag: idx, // 'idx',// TODO: change this
                // alt_tag: fbPhoto.fbImgNum, // 'idx',// TODO: change this
                archived: false,
                associated_service: "idx", // TODO: change this
                gallery: null,
                photo: fbPhoto.imgSrc,
                source: "facebook",
                largeImageHref: fbPhoto.largeImageHref,
                landing_page: sitePk,
                order: idx, // TODO: probably change this
                uncropped_gallery_photo: null,
                gallery_name: "",
                selected: false,
                saved: false,
              };
            });
          console.log("landingPageData", landingPageData);

          const fbPhotoCount = curFbData?.photos?.length;
          console.log("{...globalStateAtom.fbData, ...fbData} ", {
            ...landingPageData.fbData,
            ...curFbData,
          });
          landingPageData.fbData = {
            ...landingPageData.fbData,
            ...curFbData,
          };

          console.log("landingPageData", landingPageData);
          console.log("createLandingPageData", createLandingPageData);
          newLeads[fbSlug]["site_slug"] = siteSlug;
          const saveLandingPageData = (await handleBatchSave(
            landingPageData
          )) as any;
          const saveFacebookdataRow = (await setLandingPageInFacebookDataTable(
            siteSlug,
            fbSlug
          )) as any;
          // console.log('saveLandingPageData',saveLandingPageData)
          newLeads[fbSlug]["link_to_sample"] =
            "https://prosite.prophone.com/m/v2/" +
            createLandingPageData.siteSlug +
            "/index";
          setLeads(newLeads);

          resetNewLead();
          setLoading(false);
          forceUpdate();
        }
      }
    } catch (e) {
      // console.log('e',e)
      setLoading(false);
    }
  };
  // 3/4 run
  const addLeadAndCreateSite = async () => {
    console.log("addLeadAndCreateSite");
    try {
      setLoading(true);
      const pythonLead = await addLeadPython();
      const newLeads = leads;
      const fbSlug = newLead.facebook_slug;
      newLeads[fbSlug] = pythonLead;
      if (!newLeads[fbSlug]["lead_type"] && newLeads[fbSlug]["industry"])
        newLeads[fbSlug]["lead_type"] = newLeads[fbSlug]["industry"];
      if (!newLeads[fbSlug]["lead_type"] && !newLeads[fbSlug]["industry"])
        newLeads[fbSlug]["lead_type"] = "";
      setLeads(newLeads);
      if (newLead.facebook_slug != "") {
        const curFbDataResponse = await getFacebookData(newLead.facebook_slug);
        const curFbData = curFbDataResponse;
        // const curFbData = curFbDataResponse.found == true ? curFbDataResponse.facebook_data : null
        console.log("pythonCreateStatus.found", curFbDataResponse.found);
        console.log("pythonCreateStatus", curFbDataResponse);
        console.log("curFbData", curFbData);
        setFbData({ ...curFbData });
        newLeads[fbSlug]["fb_data"] = curFbData;

        setLeads(newLeads);
        console.log("curFbData", curFbData);

        const createLandingPageData = (await createLandingPage(
          curFbData
        )) as any;
        // return

        newLeads[fbSlug]["link_to_editor"] =
          "https://int.prophone.com/logo/" + createLandingPageData.siteSlug;
        setLeads(newLeads);
        let landingPageData = getLandingPageBlankObject(fbSlug) as any;
        const {
          completedData,
          pythonCreateStatus,
          pythonCreateMessage,
          sitePk,
          siteSlug,
          siteData,
        } = createLandingPageData;

        landingPageData = {
          ...landingPageData,
          ...createLandingPageData.completedData,
        };
        if (pythonCreateStatus === 500) {
          console.log("pythonCreateStatus");
        } else if (pythonCreateStatus === 201 && siteSlug !== undefined) {
          landingPageData.sitePk = sitePk;
          landingPageData.siteSlug = siteSlug;
          newLeads[fbSlug]["site_slug"] = siteSlug;
          console.log(pythonLead, "site_slug", siteSlug);
          console.log(newLeads[fbSlug], "site_slug", siteSlug);
          await editLeadPython(newLeads[fbSlug]);

          if (newLead.facebook_slug != "") {
            setLeads(newLeads);
            setLoading(false);
            resetNewLead();

            return;
          } else {
            alert("no facebook slug?");
            return;
          }
          return;

          return;
          landingPageData.services =
            landingPageData &&
            landingPageData.services.map((service: any) => {
              if (!service["service-description-long"])
                service["service-description-long"] = "";
              if (!service["service-description-long-pt-1"])
                service["service-description-long-pt-2"] = "";
              if (!service["service-description-long-pt-2"])
                service["service-description-long-pt-2"] = "";
              return service;
            });
          console.log(landingPageData, "landingPageData");
          console.log(fbData, "fbData landingPageData");
          console.log(curFbData, "fbData landingPageData");

          landingPageData.gallery =
            curFbData &&
            curFbData.photos &&
            curFbData?.photos.map((fbPhoto: any, idx: number) => {
              return {
                alt_tag: idx, // 'idx',// TODO: change this
                // alt_tag: fbPhoto.fbImgNum, // 'idx',// TODO: change this
                archived: false,
                associated_service: "idx", // TODO: change this
                gallery: null,
                photo: fbPhoto.imgSrc,
                source: "facebook",
                largeImageHref: fbPhoto.largeImageHref,
                landing_page: sitePk,
                order: idx, // TODO: probably change this
                uncropped_gallery_photo: null,
                gallery_name: "",
                selected: false,
                saved: false,
              };
            });
          console.log("landingPageData", landingPageData);

          const fbPhotoCount = curFbData?.photos?.length;
          console.log("{...globalStateAtom.fbData, ...fbData} ", {
            ...landingPageData.fbData,
            ...curFbData,
          });
          landingPageData.fbData = {
            ...landingPageData.fbData,
            ...curFbData,
          };

          console.log("landingPageData", landingPageData);
          console.log("createLandingPageData", createLandingPageData);
          newLeads[fbSlug]["site_slug"] = siteSlug;
          // const saveLandingPageData = await handleBatchSave(landingPageData) as any
          const saveFacebookdataRow = (await setLandingPageInFacebookDataTable(
            siteSlug,
            fbSlug
          )) as any;
          // console.log('saveLandingPageData',saveLandingPageData)
          newLeads[fbSlug]["link_to_sample"] =
            "https://prosite.prophone.com/m/v2/" +
            createLandingPageData.siteSlug +
            "/index";
          setLeads(newLeads);

          resetNewLead();
          setLoading(false);
          forceUpdate();
        }
      }
    } catch (e) {
      // console.log('e',e)
      setLoading(false);
    }
  };

  // 1/2 run
  const addLeadAndFacebookData = async () => {
    try {
      setLoading(true);
      const pythonLead = await addLeadPython();
      const newLeads = leads;
      const fbSlug = newLead.facebook_slug;
      newLeads[fbSlug] = pythonLead;
      if (!newLeads[fbSlug]["lead_type"] && newLeads[fbSlug]["industry"])
        newLeads[fbSlug]["lead_type"] = newLeads[fbSlug]["industry"];
      if (!newLeads[fbSlug]["lead_type"] && !newLeads[fbSlug]["industry"])
        newLeads[fbSlug]["lead_type"] = "";
      setLeads(newLeads);
      if (newLead.facebook_slug != "") {
        const curFbDataResponse = await getFacebookData(newLead.facebook_slug);
        const curFbData = curFbDataResponse;
        setFbData({ ...curFbData });

        if (newLead.facebook_slug != "") {
          setLeads(newLeads);
          setLoading(false);
          resetNewLead();

          return;
        } else {
          alert("no facebook slug?");
          return;
        }
        return;

        return;
        // const curFbData = curFbDataResponse.found == true ? curFbDataResponse.facebook_data : null
        console.log("pythonCreateStatus.found", curFbDataResponse.found);
        console.log("pythonCreateStatus", curFbDataResponse);
        console.log("curFbData", curFbData);
        setFbData({ ...curFbData });
        newLeads[fbSlug]["fb_data"] = curFbData;

        setLeads(newLeads);
        console.log("curFbData", curFbData);

        const createLandingPageData = (await createLandingPage(
          curFbData
        )) as any;
        newLeads[fbSlug]["link_to_editor"] =
          "https://int.prophone.com/logo/" + createLandingPageData.siteSlug;
        setLeads(newLeads);
        let landingPageData = getLandingPageBlankObject(fbSlug) as any;
        const {
          completedData,
          pythonCreateStatus,
          pythonCreateMessage,
          sitePk,
          siteSlug,
          siteData,
        } = createLandingPageData;

        landingPageData = {
          ...landingPageData,
          ...createLandingPageData.completedData,
        };
        if (pythonCreateStatus === 500) {
          console.log("pythonCreateStatus");
        } else if (pythonCreateStatus === 201 && siteSlug !== undefined) {
          landingPageData.sitePk = sitePk;
          landingPageData.siteSlug = siteSlug;
          newLeads[fbSlug]["site_slug"] = siteSlug;
          console.log(pythonLead, "site_slug", siteSlug);
          console.log(newLeads[fbSlug], "site_slug", siteSlug);
          await editLeadPython(newLeads[fbSlug]);
          landingPageData.services =
            landingPageData &&
            landingPageData.services.map((service: any) => {
              if (!service["service-description-long"])
                service["service-description-long"] = "";
              if (!service["service-description-long-pt-1"])
                service["service-description-long-pt-2"] = "";
              if (!service["service-description-long-pt-2"])
                service["service-description-long-pt-2"] = "";
              return service;
            });
          console.log(landingPageData, "landingPageData");
          console.log(fbData, "fbData landingPageData");
          console.log(curFbData, "fbData landingPageData");

          landingPageData.gallery =
            curFbData &&
            curFbData.photos &&
            curFbData?.photos.map((fbPhoto: any, idx: number) => {
              return {
                alt_tag: idx, // 'idx',// TODO: change this
                // alt_tag: fbPhoto.fbImgNum, // 'idx',// TODO: change this
                archived: false,
                associated_service: "idx", // TODO: change this
                gallery: null,
                photo: fbPhoto.imgSrc,
                source: "facebook",
                largeImageHref: fbPhoto.largeImageHref,
                landing_page: sitePk,
                order: idx, // TODO: probably change this
                uncropped_gallery_photo: null,
                gallery_name: "",
                selected: false,
                saved: false,
              };
            });
          console.log("landingPageData", landingPageData);

          const fbPhotoCount = curFbData?.photos?.length;
          console.log("{...globalStateAtom.fbData, ...fbData} ", {
            ...landingPageData.fbData,
            ...curFbData,
          });
          landingPageData.fbData = {
            ...landingPageData.fbData,
            ...curFbData,
          };

          console.log("landingPageData", landingPageData);
          console.log("createLandingPageData", createLandingPageData);
          newLeads[fbSlug]["site_slug"] = siteSlug;
          const saveLandingPageData = (await handleBatchSave(
            landingPageData
          )) as any;
          const saveFacebookdataRow = (await setLandingPageInFacebookDataTable(
            siteSlug,
            fbSlug
          )) as any;
          // console.log('saveLandingPageData',saveLandingPageData)
          newLeads[fbSlug]["link_to_sample"] =
            "https://prosite.prophone.com/m/v2/" +
            createLandingPageData.siteSlug +
            "/index";
          setLeads(newLeads);

          resetNewLead();
          setLoading(false);
          forceUpdate();
        }
      }
    } catch (e) {
      // console.log('e',e)
      setLoading(false);
    }
  };
  // 1/4 run
  const addLead = async () => {
    try {
      if (newLead.facebook_slug != "") {
        setLoading(true);
        const pythonLead = await addLeadPython();
        const newLeads = leads;
        const fbSlug = newLead.facebook_slug;
        newLeads[fbSlug] = pythonLead;
        setLeads(newLeads);
        setLoading(false);
        resetNewLead();
        return;
      } else {
        alert("no facebook slug?");
        return;
      }
      return;
      // const newLeads = leads
      // const fbSlug = newLead.facebook_slug
      newLeads[fbSlug] = pythonLead;
      if (!newLeads[fbSlug]["lead_type"] && newLeads[fbSlug]["industry"])
        newLeads[fbSlug]["lead_type"] = newLeads[fbSlug]["industry"];
      if (!newLeads[fbSlug]["lead_type"] && !newLeads[fbSlug]["industry"])
        newLeads[fbSlug]["lead_type"] = "";
      setLeads(newLeads);
      if (newLead.facebook_slug != "") {
        const curFbDataResponse = await getFacebookData(newLead.facebook_slug);
        const curFbData = curFbDataResponse;
        return;
        // const curFbData = curFbDataResponse.found == true ? curFbDataResponse.facebook_data : null
        console.log("pythonCreateStatus.found", curFbDataResponse.found);
        console.log("pythonCreateStatus", curFbDataResponse);
        console.log("curFbData", curFbData);
        setFbData({ ...curFbData });
        newLeads[fbSlug]["fb_data"] = curFbData;

        setLeads(newLeads);
        console.log("curFbData", curFbData);

        const createLandingPageData = (await createLandingPage(
          curFbData
        )) as any;
        newLeads[fbSlug]["link_to_editor"] =
          "https://int.prophone.com/logo/" + createLandingPageData.siteSlug;
        setLeads(newLeads);
        let landingPageData = getLandingPageBlankObject(fbSlug) as any;
        const {
          completedData,
          pythonCreateStatus,
          pythonCreateMessage,
          sitePk,
          siteSlug,
          siteData,
        } = createLandingPageData;

        landingPageData = {
          ...landingPageData,
          ...createLandingPageData.completedData,
        };
        if (pythonCreateStatus === 500) {
          console.log("pythonCreateStatus");
        } else if (pythonCreateStatus === 201 && siteSlug !== undefined) {
          landingPageData.sitePk = sitePk;
          landingPageData.siteSlug = siteSlug;
          newLeads[fbSlug]["site_slug"] = siteSlug;
          console.log(pythonLead, "site_slug", siteSlug);
          console.log(newLeads[fbSlug], "site_slug", siteSlug);
          await editLeadPython(newLeads[fbSlug]);
          landingPageData.services =
            landingPageData &&
            landingPageData.services.map((service: any) => {
              if (!service["service-description-long"])
                service["service-description-long"] = "";
              if (!service["service-description-long-pt-1"])
                service["service-description-long-pt-2"] = "";
              if (!service["service-description-long-pt-2"])
                service["service-description-long-pt-2"] = "";
              return service;
            });
          console.log(landingPageData, "landingPageData");
          console.log(fbData, "fbData landingPageData");
          console.log(curFbData, "fbData landingPageData");

          landingPageData.gallery =
            curFbData &&
            curFbData.photos &&
            curFbData?.photos.map((fbPhoto: any, idx: number) => {
              return {
                alt_tag: idx, // 'idx',// TODO: change this
                // alt_tag: fbPhoto.fbImgNum, // 'idx',// TODO: change this
                archived: false,
                associated_service: "idx", // TODO: change this
                gallery: null,
                photo: fbPhoto.imgSrc,
                source: "facebook",
                largeImageHref: fbPhoto.largeImageHref,
                landing_page: sitePk,
                order: idx, // TODO: probably change this
                uncropped_gallery_photo: null,
                gallery_name: "",
                selected: false,
                saved: false,
              };
            });
          console.log("landingPageData", landingPageData);

          const fbPhotoCount = curFbData?.photos?.length;
          console.log("{...globalStateAtom.fbData, ...fbData} ", {
            ...landingPageData.fbData,
            ...curFbData,
          });
          landingPageData.fbData = {
            ...landingPageData.fbData,
            ...curFbData,
          };

          console.log("landingPageData", landingPageData);
          console.log("createLandingPageData", createLandingPageData);
          newLeads[fbSlug]["site_slug"] = siteSlug;
          const saveLandingPageData = (await handleBatchSave(
            landingPageData
          )) as any;
          const saveFacebookdataRow = (await setLandingPageInFacebookDataTable(
            siteSlug,
            fbSlug
          )) as any;
          // console.log('saveLandingPageData',saveLandingPageData)
          newLeads[fbSlug]["link_to_sample"] =
            "https://prosite.prophone.com/m/v2/" +
            createLandingPageData.siteSlug +
            "/index";
          setLeads(newLeads);

          resetNewLead();
          setLoading(false);
          forceUpdate();
        }
      }
    } catch (e) {
      // console.log('e',e)
      setLoading(false);
    }
  };

  const addLeadPython = async () => {
    // newLead['lead_type'] = newLead.industry
    const getLeadsResponse = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/add_lead`,
      newLead
    );
    // console.log('getLeadsResponse',getLeadsResponse)
    // console.log('newLead',newLead)
    // return
    const leadsData = getLeadsResponse.data?.message as any;
    return leadsData;
    // setLeads(leadsData)
  };

  const handleBatchSave = async (landingPageData: any) => {
    try {
      console.log("gobucks", landingPageData);
      // setLoading(true);
      // setSavingStatus("Saving - Service Photos");
      const services = landingPageData.services;
      const handleSavingServicePhotosResponse = await axios.post(
        `${process.env.REACT_APP_NODE_SERVER_URL}/handleSavingServicePhotosEndpoint`,
        { services: services }
      );
      const handledServices = handleSavingServicePhotosResponse.data;

      // console.log("handledServices", handledServices);
      // console.log("handledServices", handledServices.data);
      const saveLandingPageDataResponse = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/edit/landingpage`,
        { ...landingPageData, services: handledServices }
      );
      const pk =
        saveLandingPageDataResponse && saveLandingPageDataResponse.data
          ? saveLandingPageDataResponse.data.pk
          : "";

      const tmpPhotos = landingPageData.gallery as any[]; // getPhotos()
      const heroPhotos = landingPageData.hero_gallery_photos as any[]; // getPhotos()
      const photos = tmpPhotos; // .slice(0,2)
      // console.log("globalStateAtom.sitePk", landingPageData.sitePk);
      // console.log("globalStateAtom REAL PK ", pk);
      console.log("photos", photos);

      // setSavingStatus("Saving - Gallery Photos");
      // Batch all photo saving items together
      const handleSavingResponse = await axios.post(
        `${process.env.REACT_APP_NODE_SERVER_URL}/handleSavingPhotosEndpoint`,
        { pk: landingPageData.sitePk, photos }
      );
      // console.log("handleSavingResponse", handleSavingResponse);
      // console.log("handleSavingResponse", handleSavingResponse.data);
      if (handleSavingResponse?.data?.status === "Success") {
        // // console.log(
        //   " gallery PHOTOS SAVE SUCCESS - ",
        //   handleSavingResponse.data.photos
        // );
        landingPageData.gallery = handleSavingResponse.data.photos;
      } else {
        throw new Error("Error on saving gallery photos");
      }
      // const handleSavingHeroPhotosResponse =  await axios.post(`${process.env.REACT_APP_NODE_SERVER_URL}/handleSavingPhotosEndpoint`, {heroPhotos: true, pk:globalStateAtom.sitePk, photos})
      // setSavingStatus("Saving - Hero Photos");
      const handleSavingHeroPhotosResponse = await axios.post(
        `${process.env.REACT_APP_NODE_SERVER_URL}/handleSavingPhotosEndpoint`,
        {
          heroPhotos: true,
          pk: landingPageData.sitePk,
          photos: heroPhotos,
        }
      );
      if (handleSavingHeroPhotosResponse?.data?.status === "Success") {
        // console.log(
        //   " HERO PHOTOS SAVE SUCCESS - ",
        //   handleSavingHeroPhotosResponse.data.photos
        // );
        landingPageData.hero_gallery_photos =
          handleSavingHeroPhotosResponse.data.photos;
      } else {
        throw new Error("Error on saving cover photos");
      }
      // console.log(
      //   "handleSavingHeroPhotosResponse",
      //   handleSavingHeroPhotosResponse
      // );
      // // console.log(
      //   "handleSavingHeroPhotosResponse",
      //   handleSavingHeroPhotosResponse.data
      // );
      return landingPageData;
    } catch (e) {
      const errorResponse = e as any;
      // console.log("e", errorResponse);
      // console.log("e", errorResponse.response);
      // // console.log('e',errorResponse.response.data.message)
      // // console.log('e',errorResponse.response.message)
      const errorMessage = errorResponse?.response?.data?.message;
      // console.log(e, "saving error");
      // alert(
      //   `There was a problem saving. Nick and Shannon broke it. Here is how:  ${
      //     errorMessage || errorResponse
      //   }`
      // );
      // setSavingStatus("");
      // setLoading(false);
      return landingPageData;
    }
  };
  const createLandingPage = async (fbData: any) => {
    // const createLandingPage = async () => {
    console.log("fbdata newLead", newLead, "newLead");
    console.log("fbdata createLandingPage", fbData, "create");
    // console.log('fbdata createLandingPage',fbData,'create')
    // console.log('newLead createLandingPage',newLead)
    const createSiteData = {
      fbSlug: newLead.facebook_slug,
      industry: newLead.industry,
      fbData: fbData,
      // fbData:{photos, testimonials: reviews, services, ...info}
    };
    console.log("newLead createSiteData", createSiteData);

    const createLandingPageWithFbDataResponse = await axios.post(
      `${process.env.REACT_APP_NODE_SERVER_URL}/createSiteWithFbDataEndpoint`,
      {
        createSiteData,
      }
    );
    // console.log('createLandingPageWithFbDataResponse',createLandingPageWithFbDataResponse)
    return createLandingPageWithFbDataResponse.data;
    // // console.log('createLandingPageWithFbDataResponse',createLandingPageWithFbDataResponse.data)
    // // console.log('createLandingPageWithFbDataResponse',createLandingPageWithFbDataResponse.data.siteSlug)
    // return "https://prosite.prophone.com/m/v2/"+createLandingPageWithFbDataResponse.data.siteSlug+"/index"
    // return "https://prosite.prophone.com/m/v2/"+createLandingPageWithFbDataResponse.data.siteSlug+"/index"
  };
  const resetNewLead = () => {
    setNewlead({
      additional_info: "",
      assigned_to: "",
      business_facebook_slug: "",
      channel: "",
      date_submitted: "",
      facebook_slug: "",
      internet_status: "",
      link_to_sample: "",
      location: "",
      link_to_editor: "",
      industry: "",
      owner: "",
      personal: "",
      priority: "",
      services: "",
      status: "",
      type: "",
      website_review_comments: "",
    });
  };

  const getLandingPageBlankObject = (facebookUrl: any) => {
    return {
      sitePk: 0 as number,
      id_key: "",
      cover_upload: "",
      multipage: true,
      company_name: "",
      company_tagline: "",
      company_type: "",
      slug: "", // turn this into a ternery like so: route.params.slug ? route.params.slug : ''
      fbSlug: facebookUrl,
      hero_gallery_photos: [],
      hero_image: "",
      // "hero_image": [],
      // "hero_type": '',
      // "unsplash": '',
      logo: "",
      custom_primary: "",
      // "shade":  '',
      custom_secondary: "",
      // "secondary_shade":  '',
      phone: "",
      email: "",
      license_number: "",
      insured: false,
      bonded: false,
      emergency: false,
      veteran: false,
      family: false,
      local: false,
      about: "",
      about_us_image: "",
      // "about_us_type": '',
      twitter: "",
      instagram: "",
      facebook: "",
      google: "",
      // "snapchat": '',
      youtube: "",
      // "linkedin": '',
      nextdoor: "",
      tiktok: "",
      // "etsy": '',
      yelp: "",
      // "thumbtack": '',
      // "featured_video": '',
      street_address: "",
      city: "",
      state: "",
      zip_code: "",
      country: "",
      services: [],
      testimonials: [],
      faqs: [],
      gallery: [],
      gallery_names: [],
      // "product": [],
      // "awards": [],
      about_header: "",
      service_header: "",
      service_subtitle: "",
      work_header: "",
      work_subtitle: "",
      partners_header: "",
      product_header: "",
      product_subtitle: "",
      // "award_header":  '',
      testimonial_header: "",
      testimonial_subtitle: "",
      paid_url: "",
      github_repo: "",
      external_link: "",
      // "company_type":  '',
      business_hours: [],
      service_area_array: [],
      service_areas: [],
      // "galleries": []
      // "fbData": {} as any,
      galleries: [],
      fbData: {
        about: "",
        address: "",
        company_name: "",
        company_type: "",
        logo: "",
        phone: "",
        extraPhotos: [],
        currentPulledPhotoCount: 0,
        photos: [],
        services: [],
        socialMediaUrls: "",
        testimonials: [],
      },
      review_link: "",
      leave_review_link_header: "",
      review_link_more: "",
      see_more_reviews_header: "",
      alternative_review_html: "",
      cta_main: "Text",
      cta_main_1: "Call",
      cta_nav: "Link",
      cta_slide_in: "Email",
      cta_main_link: "",
      cta_main_1_link: "",
      cta_nav_link: "",
      cta_slide_in_link: "",
      call_to_action_message: "Send us a Text",
      call_to_action_message_main_1: "Give us a Call",
      call_to_action_message_nav: "Book Now",
      call_to_action_message_slide_in: "Email Us",
      home_nav_title: "Home",
      service_nav_title: "Services",
      gallery_nav_title: "Galleries",
      reviews_nav_title: "Reviews",
      gallery_all_title: "All Photos",
      socials_header: "",
      three_step_header: "",
      three_step_subtitle: "",
      three_step_1_header: "",
      three_step_1_subtitle: "",
      three_step_2_header: "",
      three_step_2_subtitle: "",
      three_step_3_header: "",
      three_step_3_subtitle: "",
      // partners_header:'',
      payment_header: "",
      payment_subtitle: "",
      faq_header: "",
      team_header: "",
      team_subtitle: "",
      team_nav_title: "Team",
      jobs_header: "",
      jobs_subtitle: "",
      jobs_nav_title: "Jobs",
      cta_header: "Ready to get started?",
      cta_subtitle: "Book an appointment today.",
      final_cta_header: "Ready to get started?",
      final_cta_subtitle: "Book an appointment today.",
      call_to_action_message_nav_1: "",
      call_to_action_message_recruiting: "",
      cta_nav_1: "",
      cta_recruiting: "",
      cta_nav_1_link: "",
      cta_recruiting_link: "",
      cta_get_a_quote: "",
      cta_get_a_quote_link: "",
      call_to_action_message_get_a_quote: "",
      payments: [],
      awards: [],
      deal_header: "",
      deal_subtitle: "",
      deal_button_text: "",
      deal_button_link: "",
      teammates: [],
      team_col_1_header: "",
      team_col_1: "",
      team_col_2_header: "",
      team_col_2: "",
      black_owned: false,
      native_owned: false,
      woman_owned: false,
      latino_owned: false,
      bbb_attribute: false,
      leave_review_link_3_header: "",
      leave_review_link_3: "",
      thumbtack: "",
      bbb: "",
      homeadvisor: "",
      find_header: "",
      jobs_description: "",
      // alternative_review_js: '',
      years_in_business_attribute: 0,
      products: [],
      version: "",
      show_jobs_page: false,
      show_team_page: false,
      team_show_image: true,
      text_show_address: true,
      text_show_attachment: false,
      text_show_email: true,
      text_show_full_name: true,
      text_show_message: true,
      text_message_placeholder: "How can we help?",
      text_show_phone: true,
      text_show_promo_code: false,
      text_show_requested_date: true,
      text_show_services: true,
      prophone_contact_id: "",
      google_analytics_tag: "",
      google_analytics_tag_body: "",
      service_packages_array: [],
    };
  };
  const showAllHash = async () => {
    setShowHash({
      logo: true,
      basic_info: true,
      services: true,
      photos: true,
      faq: true,
      partnerships: true,
      reviews: true,
      faqs: true,
      team: true,
      domain: true,
      custom: true,
      FRPT: true,
    });
  };
  const hideAllHash = async () => {
    setShowHash({
      logo: false,
      basic_info: false,
      services: false,
      photos: false,
      faq: false,
      partnerships: false,
      reviews: false,
      faqs: false,
      team: false,
      domain: false,
      custom: false,
      FRPT: false,
    });
  };
  const setLandingPageInFacebookDataTable = async (
    siteSlug: string,
    facebookSlug: string
  ) => {
    // console.log('setLandingPageInFacebookDataTable facebookSlug',facebookSlug)
    // console.log('setLandingPageInFacebookDataTable siteSlug',siteSlug)
    const editLeadResponse = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/save_landing_page_to_facebook_data/${siteSlug}/${facebookSlug}`
    );
    // console.log('editLeadResponse siteSlug',editLeadResponse.data)
  };

  const editLeadPython = async (newLead: any) => {
    const editLeadResponse = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/edit_lead`,
      {
        lead: newLead,
      }
    );
    // console.log('editleadresponse',editLeadResponse)
    return editLeadResponse;
  };
  // const editLeadPython = async(leadId:string, key:string,value:string) => {

  //   const editLeadResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/edit_lead`,
  //   {
  //       leadId, key,value
  //   });
  //   // console.log('editleadresponse',editLeadResponse)
  //   return editLeadResponse
  // }
  const addLeadNoPhotos = async () => {
    try {
      setLoading(true);
      const pythonLead = await addLeadPython();
      const newLeads = leads;
      const fbSlug = newLead.facebook_slug;
      newLeads[fbSlug] = pythonLead;
      // newLeads[fbSlug]['id'] = pythonLead.id
      if (!newLeads[fbSlug]["lead_type"] && newLeads[fbSlug]["industry"])
        newLeads[fbSlug]["lead_type"] = newLeads[fbSlug]["industry"];
      if (!newLeads[fbSlug]["lead_type"] && !newLeads[fbSlug]["industry"])
        newLeads[fbSlug]["lead_type"] = "";

      setLeads(newLeads);
      if (newLead.facebook_slug != "") {
        const curFbDataResponse = await getFacebookData(newLead.facebook_slug);
        const curFbData = curFbDataResponse.found
          ? curFbDataResponse.facebook_data
          : null;
        console.log("FBDATAcurFbData", curFbData);
        setFbData({ ...curFbData });
        console.log("FBDATAcurFbData", curFbData);
        console.log("FBDATA", fbData);

        newLeads[fbSlug]["fb_data"] = curFbData;
        setLeads(newLeads);
        const createLandingPageData = (await createLandingPage(
          curFbData
        )) as any;
        console.log("createLandingPageData", createLandingPageData);

        newLeads[fbSlug]["link_to_editor"] =
          "https://int.prophone.com/logo/" + createLandingPageData.siteSlug;
        setLeads(newLeads);
        let landingPageData = getLandingPageBlankObject(fbSlug) as any;

        // console.log('landingPageData',landingPageData)
        // console.log('createLandingPageData',createLandingPageData)

        // const {sitePk, siteSlug, siteData} = createSiteResponse.data;
        const {
          completedData,
          pythonCreateStatus,
          pythonCreateMessage,
          sitePk,
          siteSlug,
          siteData,
        } = createLandingPageData;
        // let { fbData } = createLandingPageData.data;

        // landingPageData = {...landingPageData, ...createLandingPageData.completedData}

        // eslint-disable-next-line prefer-const
        // let { fbData } = createLandingPageData;

        landingPageData = {
          ...landingPageData,
          ...createLandingPageData.completedData,
        };
        // const { services } = completedData
        if (pythonCreateStatus === 500) {
          // console.log('failed',createLandingPageData.fbSlug)
          // setErrorMessage(pythonCreateMessage);
        } else if (pythonCreateStatus === 201 && siteSlug !== undefined) {
          landingPageData.sitePk = sitePk;
          landingPageData.siteSlug = siteSlug;
          newLeads[fbSlug]["site_slug"] = siteSlug;
          console.log(pythonLead, "site_slug", siteSlug);
          console.log(newLeads[fbSlug], "site_slug", siteSlug);
          // console.log(pythonLead,'siteasfasfsdf_slug',siteSlug)
          // console.log(newLead,'newLead',newLead)
          // console.log(newLeads[fbSlug],'newLeads[fbSlug]',newLeads[fbSlug])
          await editLeadPython(newLeads[fbSlug]);
          // await editLeadPython(pythonLead,'site_slug',siteSlug)
          // NEW SITE ABOVE
          // console.log('fbData',fbData)
          landingPageData.services =
            landingPageData &&
            landingPageData.services.map((service: any) => {
              if (!service["service-description-long"])
                service["service-description-long"] = "";
              if (!service["service-description-long-pt-1"])
                service["service-description-long-pt-2"] = "";
              if (!service["service-description-long-pt-2"])
                service["service-description-long-pt-2"] = "";
              return service;
            });
          landingPageData.gallery =
            fbData &&
            fbData.photos &&
            fbData?.photos.map((fbPhoto: any, idx: number) => {
              return {
                alt_tag: idx, // 'idx',// TODO: change this
                // alt_tag: fbPhoto.fbImgNum, // 'idx',// TODO: change this
                archived: false,
                associated_service: "idx", // TODO: change this
                gallery: null,
                photo: fbPhoto.imgSrc,
                source: "facebook",
                largeImageHref: fbPhoto.largeImageHref,
                landing_page: sitePk,
                order: idx, // TODO: probably change this
                uncropped_gallery_photo: null,
                gallery_name: "",
                selected: false,
                saved: false,
              };
            });
          // console.log('landingPageData',landingPageData)

          const fbPhotoCount = fbData?.photos?.length;
          // console.log("{...globalStateAtom.fbData, ...fbData} ", {
          //   ...landingPageData.fbData,
          //   ...fbData,
          // });
          landingPageData.fbData = {
            ...landingPageData.fbData,
            ...fbData,
          };

          console.log("landingPageData", landingPageData);
          console.log("createLandingPageData", createLandingPageData);
          newLeads[fbSlug]["site_slug"] = siteSlug;
          const saveLandingPageData = (await handleBatchSave(
            landingPageData
          )) as any;
          const saveFacebookdataRow = (await setLandingPageInFacebookDataTable(
            siteSlug,
            fbSlug
          )) as any;
          console.log("saveLandingPageData", saveLandingPageData);
          newLeads[fbSlug]["link_to_sample"] =
            "https://prosite.prophone.com/m/v2/" +
            createLandingPageData.siteSlug +
            "/index";
          setLeads(newLeads);

          resetNewLead();
          setLoading(false);
          forceUpdate();
        }
      }
    } catch (e) {
      // console.log('e',e)
      setLoading(false);
    }
  };
  const editExistingLead = async (event: any, key: any) => {
    // const [leads, setLeads ] = useState({} as any);
  };
  const submitExistingLeadEdit = async (event: any, key: any) => {
    const editNewLeadResponse = await editNewLead(event, key);
    const value = event.target.value;
    const editLeadResponse = await editLeadPython(newLead.id, key, value);
    const editLeadData = editLeadResponse.data;
    // console.log('newlead',newLead)
    // console.log('editLeadResponse',editLeadResponse)
    // console.log('editLeadData',editLeadData)
  };
  const editNewLead = async (event: any, key: any) => {
    // console.log('newlead . id ',newLead)
    // console.log('newlead . id ',newLead.id)
    const value = event.target.value;
    newLead[key] = value;
    // const newNewLead = {} as any
    // newNewLead[key] = event.tar
    setNewlead({ ...newLead });
  };

  // return (<>go bucks</>);
  return (
    <div className={"pt-5"} style={{ backgroundColor: "grey" }}>
      {errorMessage !== "" ? <div>{errorMessage}</div> : ""}

      {/* <h2>Add lead</h2> */}
      <div className={"w-full bg-grey-100 vh-100"}>
        {/* <div style={{width:"100%"}}>
      <select className={'rounded-md sm:h-12'} onChange={(event) => editNewLead(event, "owner")}>
        <option  value="Nick Ornitz" defaultChecked>Nick Ornitz</option>
        <option value="Ayo Coker">Ayo Coker</option>
        <option value="Pat Browne">Pat Browne</option>
        <option value="Michael Nelson">Michael Nelson</option>
        <option value="Mike Zhu">Mike Zhu</option>
        <option value="Pat Browne">Pat Browne</option>
  
      </select>
      <select  className={'rounded-md sm:h-12'}  onChange={(event) => editNewLead(event, "assigned_to")}>
        <option  value="Smiley" defaultChecked>Smiley</option>
        <option value="Jaffer">Jaffer</option>
  
      </select>
      <select  className={'rounded-md sm:h-12'} onChange={(event) => editNewLead(event, "channel")}>
        <option  value="Email" defaultChecked>Email</option>
        <option value="Cold Call">Cold Call</option>
        <option value="Referral">Referral</option>
        <option value="Organic">Organic</option>
        <option value="Other">Other</option>
  
      </select>
      <select className={'rounded-md sm:h-12'}  onChange={(event) => editNewLead(event, "priority")}>
        <option  value="Low" defaultChecked>Low</option>
        <option value="Urgent">Urgent</option>
        <option value="High">High</option>
        <option value="Medium">Medium</option>
  
      </select>
        
        <input placeholder="personal" type="text" value={newLead.personal} onChange={(event) => {editNewLead(event, "personal")}} />
        <input placeholder="facebook_slug" type="text" value={newLead.facebook_slug} onChange={(event) => {editNewLead(event, "facebook_slug")}} />
        <input placeholder="business_facebook_slug" type="text" value={newLead.business_facebook_slug} onChange={(event) => {editNewLead(event, "business_facebook_slug")}} />
        <select  className={'rounded-md sm:h-12'} onChange={(event) => editNewLead(event, "industry")}>
          <option defaultChecked>Industry</option>
          <option value="detailing-base">Auto Detailing</option>
          <option value="automotive-repair-base">Automotive Repair Shop</option>
          <option value="cleaning-base">Cleaning</option>
          <option value="construction-handyman-base">
            Construction/Remodeling
          </option>
          <option value="garage-door-base">Garage Door Technicians</option>
          <option value="in-home-base">In-Home Service</option>
          <option value="irrigation-base">Irrigation Specialists</option>
          <option value="junk-removal-base">Junk Removal</option>
          <option value="landscape-hardscape-base">
            Landscaping/Hardscaping
          </option>
          <option value="landscape-snow-base">Landscaping & Snow Removal</option>
          <option value="lawncare-base">Lawn Care</option>
          <option value="painting-renovation-base">
            Painting, Staining, & Home Renovations
          </option>
          <option value="painting-base">Painting</option>
          <option value="pressure-washing-base">Power Washing</option>
          <option value="property-management-base">
            Property Management Company
          </option>
          <option value="cleaning-residential-base">Residential Cleaning</option>
          <option value="tree-base">Tree Services</option>
          <option value="base">Other</option>
        </select>
        <input placeholder="services" type="text" value={newLead.services} onChange={(event) => {editNewLead(event, "services")}} />
        <input placeholder="additional_info" type="text" value={newLead.additional_info} onChange={(event) => {editNewLead(event, "additional_info")}} />
        <input placeholder="internet_status" type="text" value={newLead.internet_status} onChange={(event) => {editNewLead(event, "internet_status")}} />
        <input placeholder="status" type="text" value={newLead.status} onChange={(event) => {editNewLead(event, "status")}} />
        <input placeholder="link_to_sample" type="text" value={newLead.link_to_sample} onChange={(event) => {editNewLead(event, "link_to_sample")}} />
        <input placeholder="website_review_comments" type="text" value={newLead.website_review_comments} onChange={(event) => {editNewLead(event, "website_review_comments")}} />
       
      
       </div> */}
        <button
          className={
            "bg-green-700 text-white rounded-full p-2 mx-2 hover:bg-gray-50 hover:text-black cursor-pointer"
          }
          onClick={getLeads}
        >
          View Existing Leads
        </button>

        <button
          className={
            "bg-green-700 text-white rounded-full p-2 mx-2 hover:bg-gray-50 hover:text-black cursor-pointer"
          }
          onClick={getLandingPages}
        >
          View Existing Landing Pages
        </button>
        <button
          className={
            "bg-blue-900 text-white rounded-full p-2 mx-2 hover:bg-gray-50 hover:text-black cursor-pointer"
          }
          onClick={() => {
            setShowLeads(!showLeads);
          }}
        >
          {showLeads ? "Hide Leads" : "Show Leads"}
        </button>
        {/* <button
          className={'bg-blue-900 text-white rounded-full p-2 mx-2 hover:bg-gray-50 hover:text-black cursor-pointer'}
      onClick={()=>{setShowLimited(!showLimited)}}>
        {!showLimited ? <>Show Less </> : <>Show All</> }
      </button> */}

        <button
          className={
            "bg-blue-900 text-white rounded-full p-2 mx-2 hover:bg-gray-50 hover:text-black cursor-pointer"
          }
          onClick={() => {
            setShowLandingPages(!showLandingPages);
          }}
        >
          {showLandingPages ? "Hide LandingPages" : "Show LandingPages"}
        </button>
        <button
          className={
            "bg-blue-900 text-white rounded-full p-2 mx-2 hover:bg-gray-50 hover:text-black cursor-pointer"
          }
          onClick={() => {
            setShowLimited(!showLimited);
          }}
        >
          {!showLimited ? <>Show Less </> : <>Show All</>}
        </button>

        <button
          className={
            "bg-blue-900 text-white rounded-full p-2 mx-2 hover:bg-gray-50 hover:text-black cursor-pointer"
          }
          onClick={() => {
            setShowJsonFbData(!showJsonFbData);
          }}
        >
          {showJsonFbData ? "Hide Facebook Data" : "Show Facebook Data"}
        </button>

        <br />

        {showLeads ? (
          <div className="overflow-visible shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <div className={"pl-6 font-bold text-white text-xl text-left"}>
              Leads Table
            </div>
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50 text-left center">
                <tr>
                  <th
                    scope="col"
                    className=" text-center py-3.5 pl-2 pr-3 text-left text-xs font-semibold text-gray-900 "
                  >
                    {/* <input type='checkbox' className='rounded-sm mr-6'/> */}
                    Id
                  </th>
                  {/* <th scope="col" className=" text-left py-3.5 pl-2 pr-3 text-left text-xs font-semibold text-gray-900 ">
                      Date
                    </th> */}

                  <th
                    scope="col"
                    className=" text-left py-3.5 pl-2 pr-3 text-left text-xs font-semibold text-gray-900 "
                  >
                    {/* <input type='checkbox' className='rounded-sm mr-6'/> */}
                    FbSlug
                  </th>
                  <th
                    scope="col"
                    className=" text-left py-3.5 pl-2 pr-3 text-left text-xs font-semibold text-gray-900 "
                  >
                    {/* <input type='checkbox' className='rounded-sm mr-6'/> */}
                    Owner
                  </th>
                  {/* <th scope="col" className=" text-left py-3.5 pl-2 pr-3 text-left text-xs font-semibold text-gray-900 ">
                      Industry lead_type
                    </th> */}
                  <th
                    scope="col"
                    className=" text-left py-3.5 pl-2 pr-3 text-left text-xs font-semibold text-gray-900 "
                  >
                    {/* <input type='checkbox' className='rounded-sm mr-6'/> */}
                    Assigned
                  </th>
                  <th
                    scope="col"
                    className=" text-left py-3.5 pl-2 pr-3 text-left text-xs font-semibold text-gray-900 "
                  >
                    {/* <input type='checkbox' className='rounded-sm mr-6'/> */}
                    Channel
                  </th>
                  {/* <th scope="cf */}
                  <th
                    scope="col"
                    className=" text-left py-3.5 pl-2 pr-3 text-left text-xs font-semibold text-gray-900 "
                  >
                    {/* <input type='checkbox' className='rounded-sm mr-6'/> */}
                    Priority
                  </th>
                  <th
                    scope="col"
                    className=" text-left py-3.5 pl-2 pr-3 text-left text-xs font-semibold text-gray-900 "
                  >
                    {/* <input type='checkbox' className='rounded-sm mr-6'/> */}
                    Status
                  </th>
                  <th
                    scope="col"
                    className=" text-left py-3.5 pl-2 pr-3 text-left text-xs font-semibold text-gray-900 "
                  >
                    {/* <input type='checkbox' className='rounded-sm mr-6'/> */}
                    Location
                  </th>
                  <th
                    scope="col"
                    className=" text-left py-3.5 pl-2 pr-3 text-left text-xs font-semibold text-gray-900 "
                  >
                    {/* <input type='checkbox' className='rounded-sm mr-6'/> */}
                    Editor Link
                  </th>
                  <th
                    scope="col"
                    className=" text-left px-1 py-3.5 text-left text-xs font-semibold text-gray-900"
                  >
                    Template Link
                  </th>
                  {/* <th scope="col" className=" text-left px-1 py-3.5 text-left text-xs font-semibold text-gray-900">
                        Slug
                    </th> */}
                  <th
                    scope="col"
                    className=" text-left py-3.5 pl-2 pr-3 text-left text-xs font-semibold text-gray-900 "
                  >
                    {/* <input type='checkbox' className='rounded-sm mr-6'/> */}
                    Personal
                  </th>
                  <th
                    scope="col"
                    className=" text-left py-3.5 pl-2 pr-3 text-left text-xs font-semibold text-gray-900 "
                  >
                    {/* <input type='checkbox' className='rounded-sm mr-6'/> */}
                    BusFbSlug
                  </th>
                  <th
                    scope="col"
                    className=" text-left py-3.5 pl-2 pr-3 text-left text-xs font-semibold text-gray-900 "
                  >
                    {/* <input type='checkbox' className='rounded-sm mr-6'/> */}
                    Services
                  </th>
                  <th
                    scope="col"
                    className=" text-left py-3.5 pl-2 pr-3 text-left text-xs font-semibold text-gray-900 "
                  >
                    {/* <input type='checkbox' className='rounded-sm mr-6'/> */}
                    AdditionalInfo
                  </th>
                  <th
                    scope="col"
                    className=" text-left py-3.5 pl-2 pr-3 text-left text-xs font-semibold text-gray-900 "
                  >
                    {/* <input type='checkbox' className='rounded-sm mr-6'/> */}
                    InternetStatus
                  </th>
                  <th
                    scope="col"
                    className=" text-left py-3.5 pl-2 pr-3 text-left text-xs font-semibold text-gray-900 "
                  >
                    {/* <input type='checkbox' className='rounded-sm mr-6'/> */}
                    Website Review Comments
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {/* EDIT / ADD  ROW */}
                <tr className="cursor-pointer  border-y border-black ">
                  <td className="text-left whitespace-nowrap py-4 pl-2 pr-3 text-xs ">
                    <>
                      {loading ? (
                        <img
                          src="/prophone-load.gif"
                          width="50"
                          className="-mt-16"
                        />
                      ) : (
                        <></>
                      )}
                      {!newLead.id ? (
                        <>
                          <div className={"text-left flex flex-col"}>
                            <button
                              className={
                                "bg-blue-900 text-white rounded-full p-2 mx-2 hover:bg-gray-50 hover:text-black cursor-pointer"
                              }
                              onClick={addLead}
                            >
                              1. Add new lead
                            </button>
                            <button
                              className={
                                "bg-blue-900 text-white rounded-full p-2 mx-2 hover:bg-gray-50 hover:text-black cursor-pointer"
                              }
                              onClick={addLeadAndFacebookData}
                            >
                              2. Add new lead and get Facebook data
                            </button>
                            <button
                              className={
                                "bg-blue-900 text-white rounded-full p-2 mx-2 hover:bg-gray-50 hover:text-black cursor-pointer"
                              }
                              onClick={addLeadAndCreateSite}
                            >
                              3. Add lead and create site
                            </button>
                            <button
                              className={
                                "bg-blue-900 text-white rounded-full p-2 mx-2 hover:bg-gray-50 hover:text-black cursor-pointer"
                              }
                              onClick={addLeadAndCreateAndSaveSite}
                            >
                              4. Add lead and create and save site
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <button onClick={resetNewLead}>Reset </button>
                          <button
                            className={
                              "bg-blue-900 text-white rounded-full p-2 mx-2 hover:bg-gray-50 hover:text-black cursor-pointer"
                            }
                            onClick={editLead}
                          >
                            Submit Edit lead - id - {newLead.id}
                          </button>
                        </>
                      )}
                    </>
                  </td>

                  {/* <td className="whitespace-nowrap py-4 text-xs ">
                      
                      <>date
                      </> 
                      </td> */}
                  <td className="whitespace-nowrap px-1 py-4 text-xs text-gray-500">
                    <div className={"flex flex-col"}>
                      <input
                        className={
                          "`mt-0.5 border border-gray-300 appearance-none rounded-none py-2 px-4 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-xs"
                        }
                        placeholder="facebook_slug"
                        type="text"
                        value={newLead.facebook_slug}
                        onChange={(event) => {
                          editNewLead(event, "facebook_slug");
                        }}
                      />
                      <select
                        value={newLead.lead_type}
                        className={
                          "border border-gray-300 text-left rounded-md sm:h-12"
                        }
                        onChange={(event) => editNewLead(event, "lead_type")}
                      >
                        {/* <select onChange={(e) => setIndustry(e.target.value)} className={input}> */}
                        <option>Industry</option>
                        <option value="detailing-base">Auto Detailing</option>
                        <option value="automotive-repair-base">
                          Automotive Repair Shop
                        </option>
                        <option value="cleaning-base">Cleaning</option>
                        <option value="construction-handyman-base">
                          Construction/Remodeling
                        </option>
                        <option value="garage-door-base">
                          Garage Door Technicians
                        </option>
                        <option value="in-home-base">In-Home Service</option>
                        <option value="irrigation-base">
                          Irrigation Specialists
                        </option>
                        <option value="junk-removal-base">Junk Removal</option>
                        <option value="landscape-hardscape-base">
                          Landscaping/Hardscaping
                        </option>
                        <option value="landscape-snow-base">
                          Landscaping & Snow Removal
                        </option>
                        <option value="lawncare-base">Lawn Care</option>
                        <option value="painting-renovation-base">
                          Painting, Staining, & Home Renovations
                        </option>
                        <option value="painting-base">Painting</option>
                        <option value="pressure-washing-base">
                          Power Washing
                        </option>
                        <option value="property-management-base">
                          Property Management Company
                        </option>
                        <option value="cleaning-residential-base">
                          Residential Cleaning
                        </option>
                        <option value="tree-base">Tree Services</option>
                        <option value="base">Other</option>
                      </select>
                    </div>
                  </td>
                  <td className="whitespace-nowrap py-4 pl-2 pr-3 text-xs ">
                    <>
                      <div className="flex items-center">
                        <div
                          className="h-10  flex-shrink-0"
                          onClick={() => {
                            // console.log('bucks contactcontact',newLead)
                            // console.log('bucks newLeadnewLead',leads)
                          }}
                        >
                          <select
                            value={newLead.owner}
                            className={"rounded-md sm:h-12"}
                            onChange={(event) => editNewLead(event, "owner")}
                          >
                            <option value="Nick Ornitz">Nick Ornitz</option>
                            <option value="Ayo Coker">Ayo Coker</option>
                            <option value="Pat Browne">Pat Browne</option>
                            <option value="Michael Nelson">
                              Michael Nelson
                            </option>
                            <option value="Mike Zhu">Mike Zhu</option>
                            <option value="Pat Browne">Pat Browne</option>
                          </select>
                          {/* aaa {contact.owner} */}
                          {/* {contact.owner ? 
                            (contact['showEdit']['owner'] ? <input value={contact.owner} onChange={()=>{editExistinglead()}}></input> : <>aaaa{contact.owner}</>)
                            : "noowner"} */}
                        </div>
                      </div>
                    </>
                  </td>

                  {/* <td className="whitespace-nowrap px-1 py-4 text-xs text-gray-500 text-left">
                            <select  value={newLead.lead_type} className={'text-left rounded-md sm:h-12'} onChange={(event) => editNewLead(event, "lead_type")}>
                                <option >Industry</option>
                                <option value="detailing-base">Auto Detailing</option>
                                <option value="automotive-repair-base">Automotive Repair Shop</option>
                                <option value="cleaning-base">Cleaning</option>
                                <option value="construction-handyman-base">
                                  Construction/Remodeling
                                </option>
                                <option value="garage-door-base">Garage Door Technicians</option>
                                <option value="in-home-base">In-Home Service</option>
                                <option value="irrigation-base">Irrigation Specialists</option>
                                <option value="junk-removal-base">Junk Removal</option>
                                <option value="landscape-hardscape-base">
                                  Landscaping/Hardscaping
                                </option>
                                <option value="landscape-snow-base">Landscaping & Snow Removal</option>
                                <option value="lawncare-base">Lawn Care</option>
                                <option value="painting-renovation-base">
                                  Painting, Staining, & Home Renovations
                                </option>
                                <option value="painting-base">Painting</option>
                                <option value="pressure-washing-base">Power Washing</option>
                                <option value="property-management-base">
                                  Property Management Company
                                </option>
                                <option value="cleaning-residential-base">Residential Cleaning</option>
                                <option value="tree-base">Tree Services</option>
                                <option value="base">Other</option>
                            </select>
                          </td> */}
                  <td className="whitespace-nowrap py-4 pl-2 pr-3 text-xs ">
                    <>
                      <div className="flex items-center">
                        <div className="h-10 flex-shrink-0">
                          {/* {newLead.assigned_to ? newLead.assigned_to : "noowner"} */}
                          <select
                            value={newLead.assigned_to}
                            className={"rounded-md sm:h-12"}
                            onChange={(event) =>
                              editNewLead(event, "assigned_to")
                            }
                          >
                            <option value="Smiley" defaultChecked>
                              Smiley
                            </option>
                            <option value="Jaffer">Jaffer</option>
                          </select>
                        </div>
                      </div>
                    </>
                  </td>

                  <>
                    <td className=" whitespace-nowrap px-1 py-4 text-xs text-gray-500">
                      {/* {newLead.channel ? newLead.channel : "nochannel"} */}

                      {/* <td className="whitespace-nowrap px-1 py-4 text-xs text-gray-500"> */}
                      {/* <select  value={newLead.lead_type} className={'rounded-m d sm:h-12'}  */}
                      <select
                        value={newLead.channel}
                        className={"rounded-md sm:h-12"}
                        onChange={(event) => editNewLead(event, "channel")}
                      >
                        <option value="Email" defaultChecked>
                          Email
                        </option>
                        <option value="Cold Call">Cold Call</option>
                        <option value="Referral">Referral</option>
                        <option value="Organic">Organic</option>
                        <option value="Other">Other</option>
                      </select>
                      {/* slug:{newLead.site_slug}|<button onClick={()=>{
                            setCurrentSlug(newLead.site_slug);
                            clickSlug()
                          }}>setcurrentslug</button> */}
                    </td>
                    {/* <td className="whitespace-nowrap px-1 py-4 text-xs text-gray-500">
                            {newLead.date_submitted ? newLead.date_submitted : "date_submitted"}
                          </td> */}
                    <td className="whitespace-nowrap px-1 py-4 text-xs text-gray-500">
                      <select
                        value={newLead.priority}
                        className={"rounded-md sm:h-12"}
                        onChange={(event) => editNewLead(event, "priority")}
                      >
                        <option value="Low">Low</option>
                        <option value="Urgent">Urgent</option>
                        <option value="High">High</option>
                        <option value="Medium">Medium</option>
                      </select>
                    </td>

                    <td className="whitespace-nowrap px-1 py-4 text-xs text-gray-500">
                      {/* {newLead.status ? newLead.status : "status"}xyz */}
                      {/* <input placeholder="status" type="text" value={newLead.status} onChange={(event) => {editNewLead(event, "status")}} /> */}

                      {/* <input placeholder="status" type="text" value={newLead.status} onChange={(event) => {editNewLead(event, "status")}} 
                          className={'`mt-0.5 border border-gray-300 appearance-none rounded-none py-2 px-4 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-xs'}
                          /> */}
                      <select
                        value={newLead.status}
                        className={"rounded-md sm:h-12"}
                        onChange={(event) => editNewLead(event, "status")}
                      >
                        <option value="Yet to start">Yet to start</option>
                        {/* <option  value="Low" defaultChecked>Todo</option> */}
                        <option value="Done">Done</option>
                        <option value="In Progress">In Progress</option>
                        <option value="Need more info">Need more info</option>
                        <option value="Don't do">Don't do</option>
                      </select>
                    </td>
                    <td className="whitespace-nowrap px-1 py-4 text-xs text-gray-500">
                      {/* {newLead['location'] ? <a  className={'text-blue-400'} target="_blank" href={newLead.location}>location - {newLead.location}</a> : <>location</>} */}
                      <input
                        className={
                          "`mt-0.5 border border-gray-300 appearance-none rounded-none py-2 px-4 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-xs"
                        }
                        placeholder="location"
                        type="text"
                        value={newLead.location}
                        onChange={(event) => {
                          editNewLead(event, "location");
                        }}
                      />
                    </td>
                    <td className="whitespace-nowrap px-1 py-4 text-xs text-gray-500">
                      {newLead["link_to_editor"] ? (
                        <a
                          className={"text-blue-400"}
                          target="_blank"
                          href={newLead.link_to_editor}
                        >
                          Editor - {newLead.site_slug}
                        </a>
                      ) : (
                        <>NoEditor</>
                      )}
                    </td>
                    <td className="whitespace-nowrap px-1 py-4 text-xs text-gray-500">
                      {/* <input placeholder="link_to_sample" type="text" value={newLead.link_to_sample} onChange={(event) => {editNewLead(event, "link_to_sample")}} /> */}

                      {newLead["link_to_sample"] ? (
                        <a
                          className={"text-blue-400"}
                          target="_blank"
                          href={newLead.link_to_sample}
                        >
                          Template - {newLead.site_slug}
                        </a>
                      ) : (
                        <>NoTemp</>
                      )}
                    </td>

                    <td className="whitespace-nowrap px-1 py-4 text-xs text-gray-500">
                      {/* {newLead.personal ? newLead.personal : "personal"} */}
                      <input
                        className={
                          "`mt-0.5 border border-gray-300 appearance-none rounded-none py-2 px-4 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-xs"
                        }
                        placeholder="personal"
                        type="text"
                        value={newLead.personal}
                        onChange={(event) => {
                          editNewLead(event, "personal");
                        }}
                      />
                    </td>

                    <td className="whitespace-nowrap px-1 py-4 text-xs text-gray-500">
                      <input
                        className={
                          "`mt-0.5 border border-gray-300 appearance-none rounded-none py-2 px-4 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-xs"
                        }
                        placeholder="business_facebook_slug"
                        type="text"
                        value={newLead.business_facebook_slug}
                        onChange={(event) => {
                          editNewLead(event, "business_facebook_slug");
                        }}
                      />
                      {/* {newLead.business_facebook_slug ? newLead.business_facebook_slug : "business_facebook_slug"} */}
                    </td>
                    <td className="whitespace-nowrap px-1 py-4 text-xs text-gray-500">
                      <input
                        className={
                          "`mt-0.5 border border-gray-300 appearance-none rounded-none py-2 px-4 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-xs"
                        }
                        placeholder="services"
                        type="text"
                        value={newLead.services}
                        onChange={(event) => {
                          editNewLead(event, "services");
                        }}
                      />
                      {/* {newLead.services ? newLead.services : "services"} */}
                    </td>
                    <td className="whitespace-nowrap px-1 py-4 text-xs text-gray-500">
                      <input
                        className={
                          "`mt-0.5 border border-gray-300 appearance-none rounded-none py-2 px-4 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-xs"
                        }
                        placeholder="additional_info"
                        type="text"
                        value={newLead.additional_info}
                        onChange={(event) => {
                          editNewLead(event, "additional_info");
                        }}
                      />
                      {/* {newLead.additional_info ? newLead.additionial_info : "additional_info"} */}
                    </td>
                    <td className="whitespace-nowrap px-1 py-4 text-xs text-gray-500">
                      {/* {newLead.internet_status ? newLead.internet_status : "internet_status"} */}
                      <input
                        className={
                          "`mt-0.5 border border-gray-300 appearance-none rounded-none py-2 px-4 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-xs"
                        }
                        placeholder="internet_status"
                        type="text"
                        value={newLead.internet_status}
                        onChange={(event) => {
                          editNewLead(event, "internet_status");
                        }}
                      />
                    </td>
                    <td className="whitespace-nowrap px-1 py-4 text-xs text-gray-500">
                      <input
                        className={
                          "`mt-0.5 border border-gray-300 appearance-none rounded-none py-2 px-4 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-xs"
                        }
                        placeholder="website_review_comments"
                        type="text"
                        value={newLead.website_review_comments}
                        onChange={(event) => {
                          editNewLead(event, "website_review_comments");
                        }}
                      />

                      {/* {newLead.website_review_comments ? newLead.website_review_comments : "website_review_comments"} */}
                    </td>
                  </>
                </tr>
                {/* DISPLAY ROWS */}
                {Object.keys(leads).map((contactKey: any, i: number) => {
                  const contact = leads[contactKey];
                  // console.log('contact display row',contact)
                  const keys = Object.keys(contact);
                  return showLimited && i > showLimitMax ? (
                    <></>
                  ) : (
                    <tr className="cursor-pointer border-y-2 border-black bg-gray-100">
                      <td className="text-left whitespace-nowrap  text-xs text-gray-500">
                        {/* {contact.id ? contact.id : "id"} */}
                        <div className={"flex flex-col"}>
                          <button
                            className={
                              "bg-blue-900 text-white rounded-full p-2 mx-2 hover:bg-gray-50 hover:text-black cursor-pointer"
                            }
                            onClick={() => {
                              setNewlead({
                                ...contact,
                              });
                            }}
                          >
                            Edit Lead - {contact.id}
                          </button>
                          <button
                            className={
                              "p-3 px-6 rounded-full border-2 text-xs bg-blue-100"
                            }
                            onClick={async () => {
                              setCurrentSlug(contact.site_slug);
                              await clickSlug(contact.site_slug);
                            }}
                          >
                            {contact.site_slug
                              ? "Edit - " + contact.site_slug
                              : "Not ready - Edit lead "}
                          </button>

                          <div className={"text-sm text-center"}>
                            {" "}
                            {contact.date_submitted
                              ? new Date(
                                  contact.date_submitted
                                ).toLocaleString()
                              : "date_submitted"}
                          </div>
                        </div>
                      </td>
                      {/* <td className="text-left whitespace-nowrap  text-xs text-gray-500">
                            {contact.date_submitted ? (new Date(contact.date_submitted)).toLocaleString()  : "date_submitted"}
                          </td>  */}

                      <td className="text-left whitespace-nowrap px-1 py-4 text-xs text-gray-500">
                        {contact.facebook_slug
                          ? contact.facebook_slug
                          : "facebook_slug"}
                        <br />
                        {contact.lead_type ? contact.lead_type : "lead_type"}
                      </td>

                      <td className="text-left whitespace-nowrap py-4 pl-2 pr-3 text-xs ">
                        <>
                          <div className="flex items-center">
                            <div className="h-10  flex-shrink-0">
                              {contact.owner}
                            </div>
                          </div>
                        </>
                      </td>

                      <td className="text-left whitespace-nowrap py-4 pl-2 pr-3 text-xs ">
                        <>
                          <div className="flex items-center">
                            <div className="h-10 flex-shrink-0">
                              {contact.assigned_to
                                ? contact.assigned_to
                                : "not assigned"}
                            </div>
                          </div>
                        </>
                      </td>

                      <>
                        <td className="flex items-center text-left whitespace-nowrap px-1 py-4 text-xs text-gray-500">
                          {contact.channel ? contact.channel : "no channel"}

                          {/* slug:{contact.site_slug}|<button onClick={()=>{
                                  setCurrentSlug(contact.site_slug);
                                  clickSlug()
                                }}>setcurrentslug</button> */}
                        </td>
                        <td className="text-left whitespace-nowrap px-1 py-4 text-xs text-gray-500">
                          {contact.priority ? contact.priority : "no priority"}
                        </td>
                        <td className="text-left whitespace-nowrap px-1 py-4 text-xs text-gray-500">
                          {contact.status ? contact.status : "no status"}
                        </td>
                        <td className="text-left whitespace-nowrap px-1 py-4 text-xs text-gray-500">
                          {contact.location ? contact.location : "no location"}
                        </td>
                        <td className="text-left whitespace-nowrap px-1 py-4 text-xs text-gray-500">
                          {contact["link_to_editor"] ? (
                            <a
                              className={"text-blue-400"}
                              target="_blank"
                              href={contact.link_to_editor}
                            >
                              Editor
                            </a>
                          ) : (
                            <></>
                          )}
                        </td>
                        <td className="text-left whitespace-nowrap px-1 py-4 text-xs text-gray-500">
                          {contact["link_to_sample"] ? (
                            <a
                              className={"text-blue-400"}
                              target="_blank"
                              href={contact.link_to_sample}
                            >
                              Template
                            </a>
                          ) : (
                            <></>
                          )}
                          {/* </td>
                                <td className="text-left whitespace-nowrap px-1 py-4 text-xs text-gray-500"> */}
                          <button
                            className={
                              "p-3 px-6 rounded-full border-2 text-xs bg-blue-100"
                            }
                            onClick={async () => {
                              setCurrentSlug(contact.site_slug);
                              await clickSlug(contact.site_slug);
                            }}
                          >
                            Select
                          </button>
                        </td>
                        {/* <td className="text-left whitespace-nowrap px-1 py-4 text-xs text-gray-500">
                                {contact.industry ? contact.industry : "industry"}
                                </td> */}
                        <td className="text-left whitespace-nowrap px-1 py-4 text-xs text-gray-500">
                          {contact.personal ? contact.personal : "no personal"}
                        </td>
                        <td className="text-left whitespace-nowrap px-1 py-4 text-xs text-gray-500">
                          {contact.business_facebook_slug
                            ? contact.business_facebook_slug
                            : "business_facebook_slug"}
                        </td>
                        {/* <td className="text-left whitespace-nowrap px-1 py-4 text-xs text-gray-500">
                                {contact.lead_type ? contact.lead_type : "lead_type"}
                                </td> */}
                        <td className="text-left whitespace-nowrap px-1 py-4 text-xs text-gray-500">
                          {contact.services ? contact.services : "no services"}
                        </td>
                        <td className="text-left whitespace-nowrap px-1 py-4 text-xs text-gray-500">
                          {contact.additional_info
                            ? contact.additional_info
                            : "no additional_info"}
                        </td>
                        <td className="text-left whitespace-nowrap px-1 py-4 text-xs text-gray-500">
                          {contact.internet_status
                            ? contact.internet_status
                            : "no internet_status"}
                        </td>
                        <td className="text-left whitespace-nowrap px-1 py-4 text-xs text-gray-500">
                          {contact.website_review_comments
                            ? contact.website_review_comments
                            : "no website_review_comments"}
                        </td>
                      </>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div>
        {showJsonFbData ? <ReactJson src={fbData} /> : <></>}

        <div style={{ display: "flex" }}></div>
        <div className="">
          <div className="mt-3 flex flex-col">
            <div className="-my-2 sm:-mx-4 overflow-x-auto lg:-mx-8 pb-4">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                {showLandingPages ? (
                  <div className="overflow-visible shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <div
                      className={"pl-6 font-bold text-white text-xl text-left"}
                    >
                      Landing Pages Table
                    </div>
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-2 pr-3 text-left text-xs font-semibold text-gray-900 "
                          >
                            {/* <input type='checkbox' className='rounded-sm mr-6'/> */}
                            Editor
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-2 pr-3 text-left text-xs font-semibold text-gray-900 "
                          >
                            {/* <input type='checkbox' className='rounded-sm mr-6'/> */}
                            Slug / Template
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-2 pr-3 text-left text-xs font-semibold text-gray-900 "
                          >
                            {/* <input type='checkbox' className='rounded-sm mr-6'/> */}
                            Created
                          </th>
                          {/* <th></th> */}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {Object.keys(landingPages).map((contactKey: any, i) => {
                          const contact = landingPages[contactKey];
                          // const isSelected = selectedContacts.includes(contact)
                          return showLimited && i > showLimitMax ? (
                            <></>
                          ) : (
                            <tr className="cursor-pointer">
                              <td className="text-left text-left whitespace-nowrap px-1 py-4 text-xs text-gray-500">
                                <button
                                  className={
                                    "bg-blue-900 text-white rounded-full p-2 mx-2 hover:bg-gray-50 hover:text-black cursor-pointer"
                                  }
                                  onClick={async () => {
                                    setCurrentSlug(contact.slug);
                                    await clickSlug(contact.slug);
                                  }}
                                >
                                  Edit / View Landing Page
                                </button>
                              </td>
                              <td className="text-left whitespace-nowrap py-4 pl-2 pr-3 text-xs ">
                                <>
                                  <div className="flex items-center">
                                    <div className="h-10 flex-shrink-0">
                                      <a
                                        href={
                                          "https://prosite.prophone.com/m/v2/" +
                                          contact.slug +
                                          "/index"
                                        }
                                        target="_blank"
                                      >
                                        {contact.company_name
                                          ? contact.company_name
                                          : ""}{" "}
                                        +{" "}
                                        {contact.slug
                                          ? contact.slug
                                          : "no slug?"}
                                      </a>
                                      {/* {contact['link_to_editor'] ? <a href={contact.link_to_editor}>Editor</a> : <>a</>} */}
                                    </div>
                                  </div>
                                </>
                              </td>
                              <td className="text-left whitespace-nowrap py-4 pl-2 pr-3 text-xs ">
                                <>
                                  <div className="flex items-center">
                                    <div className="h-10 flex-shrink-0">
                                      {contact.creation_date
                                        ? contact.creation_date
                                        : "creation_date"}

                                      {/* {newLead.fbSlug}b
                     {contact['link_to_sample'] ? <a href={contact.link_to_sample}>Template</a> : <></>} */}
                                    </div>
                                  </div>
                                </>
                              </td>

                              <>
                                {/* <td className="flex items-center whitespace-nowrap px-1 py-4 text-xs text-gray-500">
              {contact.channel ? contact.channel : "nochannel"}
              </td> */}

                                {/* slug:{contact.site_slug}|<button onClick={()=>{
                setCurrentSlug(contact.site_slug);
                clickSlug()
              }}>setcurrentslug</button> */}

                                {/* 
              <td className="whitespace-nowrap px-1 py-4 text-xs text-gray-500">
              {contact['link_to_editor'] ? <a href={contact.link_to_editor}>Editor</a> : <>no editor</>}
              </td>  */}
                                {/* <td className="whitespace-nowrap px-1 py-4 text-xs text-gray-500">
              {contact['link_to_sample'] ? <a href={contact.link_to_sample}>Template</a> : <>no editor</>}
              </td> */}
                                {/* <td className="whitespace-nowrap px-1 py-4 text-xs text-gray-500">
              slug:{contact.slug}|<button onClick={async ()=>{
                setCurrentSlug(contact.slug);
                await clickSlug(contact.slug)
              }}>setcurrentslug</button> 
              </td> */}
                              </>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {error && (
        <p className="text-red-500">
          There was an error building this website. Please try again after
          yelling at Dylan.
        </p>
      )}
      <div>
        <div>
          Checkou link id -{" "}
          {globalStateAtom.sitePk ? globalStateAtom.sitePk : "no site pk"}{" "}
        </div>
        <div className={"pl-6 font-bold text-white text-xl text-left"}>
          Site Builder / Editor
        </div>
        <input
          value={currentSlug}
          onChange={(event) => {
            setCurrentSlug(event.target.value);
          }}
        />
        {/* <input placeholder="Owner" type="text" value={newLead.owner} onChange={(event) => {editNewLead(event, "owner")}} /> */}
        <button
          className={
            "bg-blue-900 text-white  rounded-full p-2 mx-2 hover:bg-gray-50 hover:text-black cursor-pointer"
          }
          onClick={(event) => {
            clickSlug(currentSlug);
          }}
        >
          set slug
        </button>
        <div>
          {showEditor ? (
            <>
              <ul className="w-full flex justify-around transform -translate-y-2 flex-row mt-5 bg-white">
                <li
                  onClick={() => {
                    showAllHash();
                  }}
                  className="text-gray-500"
                >
                  Show All
                </li>
                <li
                  onClick={() => {
                    hideAllHash();
                  }}
                  className="text-gray-500"
                >
                  Hide All
                </li>
                {/* <li onClick={()=>{setShowHash({...showHash, 'logo': !showHash['logo']})}} className="text-gray-500">
           Logo
       </li> */}{" "}
                <button
                  title="save"
                  onClick={handleSave}
                  className={
                    "bg-blue-900 text-white bold font-extrabold rounded-full p-2 mx-2 hover:bg-gray-50 hover:text-black cursor-pointer"
                  }
                >
                  Save Landing Page
                </button>
                <li
                  onClick={() => {
                    setShowHash({
                      ...showHash,
                      photos: !showHash["photos"],
                    });
                  }}
                  className={`${
                    !showHash["photos"] ? `bg-gray-800` : ` bg-blue-900`
                  }   
         text-white     rounded-full p-2 mx-2 hover:bg-gray-50 hover:text-black cursor-pointer  `}
                >
                  Photos
                </li>
                <li
                  onClick={() => {
                    setShowHash({
                      ...showHash,
                      basic_info: !showHash["basic_info"],
                    });
                  }}
                  className={`    ${
                    !showHash["basic_info"] ? `bg-gray-800` : ` bg-blue-900`
                  }   
         text-white     rounded-full p-2 mx-2 hover:bg-gray-50 hover:text-black cursor-pointer `}
                >
                  Basic Info
                </li>
                <li
                  onClick={() => {
                    setShowHash({
                      ...showHash,
                      custom: !showHash["custom"],
                    });
                  }}
                  className={`${
                    !showHash["custom"] ? `bg-gray-800` : ` bg-blue-900`
                  }   
         text-white     rounded-full p-2 mx-2 hover:bg-gray-50 hover:text-black cursor-pointer`}
                >
                  Custom
                </li>
                <li
                  onClick={() => {
                    setShowHash({
                      ...showHash,
                      services: !showHash["services"],
                    });
                  }}
                  className={`${
                    !showHash["services"] ? `bg-gray-800` : ` bg-blue-900`
                  }   
         text-white     rounded-full p-2 mx-2 hover:bg-gray-50 hover:text-black cursor-pointer  `}
                >
                  Services
                </li>
                <li
                  onClick={() => {
                    setShowHash({
                      ...showHash,
                      FRPT: !showHash["FRPT"],
                    });
                  }}
                  className={`${
                    !showHash["FRPT"] ? `bg-gray-800` : ` bg-blue-900`
                  }   
         text-white     rounded-full p-2 mx-2 hover:bg-gray-50 hover:text-black cursor-pointer `}
                >
                  FRPT
                </li>
                <li
                  onClick={() => {
                    setShowHash({
                      ...showHash,
                      faqs: !showHash["faqs"],
                    });
                  }}
                  className={`${
                    !showHash["faqs"] ? `bg-gray-800` : ` bg-blue-900`
                  }   
         text-white     rounded-full p-2 mx-2 hover:bg-gray-50 hover:text-black cursor-pointer   `}
                >
                  FAQs
                </li>
                <li
                  onClick={() => {
                    setShowHash({
                      ...showHash,
                      reviews: !showHash["reviews"],
                    });
                  }}
                  className={`${
                    !showHash["reviews"] ? `bg-gray-800` : ` bg-blue-900`
                  }   
         text-white     rounded-full p-2 mx-2 hover:bg-gray-50 hover:text-black cursor-pointer   `}
                >
                  Reviews
                </li>
                <li
                  onClick={() => {
                    setShowHash({
                      ...showHash,
                      partnerships: !showHash["partnerships"],
                    });
                  }}
                  className={`${
                    !showHash["partnerships"] ? `bg-gray-800` : ` bg-blue-900`
                  }    
         text-white     rounded-full p-2 mx-2 hover:bg-gray-50 hover:text-black cursor-pointer   `}
                >
                  Partnerships
                </li>
                <li
                  onClick={() => {
                    setShowHash({
                      ...showHash,
                      team: !showHash["team"],
                    });
                  }}
                  className={`${
                    !showHash["team"] ? `bg-gray-800` : ` bg-blue-900`
                  }   
         text-white     rounded-full p-2 mx-2 hover:bg-gray-50 hover:text-black cursor-pointer   `}
                >
                  Teamm
                </li>
                {/* <li  onClick={()=>{setShowHash({...showHash, 'domain': !showHash['domain']})}} className={` +  `text-gray-500`}>
           Domain
       </li> */}
              </ul>
            </>
          ) : (
            <></>
          )}

          {showEditor ? (
            <>
              {/* {showHash['logo'] ? <Logo /> : <></>} */}

              {/* {showHash['domain'] ? <DomainPage /> : <></>} */}
              {/* <Nav />  */}
              {showHash["photos"] ? <PhotosPage /> : <></>}
              {showHash["basic_info"] ? <BasicInfoPage /> : <></>}
              {showHash["custom"] ? <CustomPage /> : <></>}
              {showHash["services"] ? <ServicesPage /> : <></>}
              <div className="flex">
                {showHash["FRPT"] ? (
                  <>
                    {showHash["faqs"] ? <FAQsPage /> : <></>}
                    {showHash["reviews"] ? <ReviewsPage /> : <></>}
                    {showHash["partnerships"] ? <PartnershipsPage /> : <></>}
                    {showHash["team"] ? <TeamPage /> : <></>}
                  </>
                ) : (
                  <></>
                )}
                {/* {showHash['faqs'] ? <FAQsPage /> : <></>}
          {showHash['reviews'] ? <ReviewsPage /> : <></>}
          {showHash['partnerships'] ? <PartnershipsPage /> : <></>}
          {showHash['team'] ? <TeamPage /> : <></>} */}
              </div>
              {/* <BasicInfoPage /><ServicesPage /><PhotosPage /><FAQsPage /><ReviewsPage /><PartnershipsPage /><TeamPage /><DomainPage /><CustomPage /> */}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewSiteTwo;
