/* eslint-disable */
import { useState } from "react";
import axios from "axios";
import { ExtractAtomValue, useAtom } from "jotai";
import globalState from "../../../store";

interface GenerateProps {
  tagline: boolean;
}

const Generate: React.FC<GenerateProps> = ({ tagline, }) => {
  const [loading, setLoading] = useState(false);
  const [globalStateAtom, setGlobalStateAtom] = useAtom(globalState);

  const generate = async () => {
    try {
      setLoading(true);

      if (tagline) {
        const company_tagline = await generateTagline(globalStateAtom);
        setGlobalStateAtom({ ...globalStateAtom, company_tagline })
      } else {
        const about = await generateAboutUs(globalStateAtom);
        setGlobalStateAtom({ ...globalStateAtom, about })
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div onClick={generate}>
      {loading ? (
        <img src="/loading.gif" width="20" alt="loading" />
      ) : (
        <p>Generate</p>
      )}
    </div>
  );
};

export async function generateTagline(globalStateAtom: ExtractAtomValue<typeof globalState>) {
  try {
    const location = getLocation(globalStateAtom.service_area_array)
    const response = await axios.get<{ message: string }>(`${process.env.REACT_APP_SERVER_URL}/api/landing-pages/generate-tagline`, { params: { company: globalStateAtom.company_name, location, industry: globalStateAtom.company_type, primary_keyword: globalStateAtom.primary_keyword } })
    return response.data.message
  } catch (error) {
    alert(`Error generating: Verify that Company Name, Industry and at least one Service Area are filled.`);
    throw error;
  }
}

export async function generateAboutUs(globalStateAtom: ExtractAtomValue<typeof globalState>) {
  try {
    const location = getLocation(globalStateAtom.service_area_array)
    const response = await axios.get<{ message: string }>(`${process.env.REACT_APP_SERVER_URL}/api/landing-pages/generate-about-us`, { params: { company: globalStateAtom.company_name, location, industry: globalStateAtom.company_type, keywords: globalStateAtom.keywords } })
    return response.data.message
  } catch (error) {
    alert(`Error generating: Verify that Company Name, Industry and at least one Service Area are filled.`);
    throw error;
  }
}

export async function generateMetadataTitle(globalStateAtom: ExtractAtomValue<typeof globalState>) {
  try {
    const [city, state] = getCityState(globalStateAtom.service_area_array)
    const response = await axios.get<{ message: string }>(`${process.env.REACT_APP_SERVER_URL}/api/seo/generate-meta-title`, { params: { company_name: globalStateAtom.company_name, company_type: globalStateAtom.company_type, city, state, primary_keyword: globalStateAtom.primary_keyword } })
    return response.data.message
  } catch (error) {
    alert(`Error generating: Verify that Company Name, Industry and at least one Service Area are filled.`);
    throw error;
  }
}

export async function generateMetadataDescription(globalStateAtom: ExtractAtomValue<typeof globalState>) {
  try {
    const [city, state] = getCityState(globalStateAtom.service_area_array)
    const response = await axios.get<{ message: string }>(`${process.env.REACT_APP_SERVER_URL}/api/seo/generate-meta-description`, { params: { company_name: globalStateAtom.company_name, company_type: globalStateAtom.company_type, city, state, primary_keyword: globalStateAtom.primary_keyword } })
    return response.data.message
  } catch (error) {
    alert(`Error generating: Verify that Company Name, Industry and at least one Service Area are filled.`);
    throw error;
  }
}

function getLocation(service_area_array: [string, string][]) {
  if (!service_area_array || !service_area_array.length) return ""
  const [city, state] = service_area_array[0]
  return city || state ? `${city}, ${state}` : ""
}

function getCityState(service_area_array: [string, string][]): [string, string] {
  if (!service_area_array || !service_area_array.length) return ["", ""]
  return service_area_array[0]
}

export default Generate;
