/* eslint-disable */
import {
  borderedInputWhite,
  buttonWhiteSmall,
  label,
} from "../../../shared/styles";
import globalState from "../../../store";
import { useAtom } from "jotai";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AppContext } from "../../../App";

const CheckoutLink: React.FC = () => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const [checkoutLink, setCheckoutLink] = useState("");

  const [proPhoneContacts, setProPhoneContacts] = useState([]);
  const [stripePlans, setStripePlans] = useState([]);
  const [selectedProphoneContact, setSelectedProphoneContact] = useState(
    globalStateAtom?.prophone_contact_id || ""
  );
  const [showCheckoutLink, setShowCheckoutLink] = useState(false);
  const { isSaveClicked, saveClickedCount, addOrChangePaidUrl } =
    useContext(AppContext);

  useEffect(() => {
    getPlansFromStripe();
  }, []);

  useEffect(() => {
    async function getLandingPage() {
      const slug = globalStateAtom?.slug;
      const fetchedDataResponse = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/getLandingPage/${slug}`
      );

      const checkoutLink = `app.toplinepro.com/checkout?id=${globalStateAtom.sitePk}`;
      setCheckoutLink(checkoutLink);

      const data = fetchedDataResponse?.data?.message;

      if (
        data?.prophone_contact_id &&
        data?.paid_url &&
        data?.salesloft_id &&
        data?.selected_plan &&
        data?.selected_plan !== "Please select a plan"
      ) {
        setShowCheckoutLink(true);
      } else {
        setShowCheckoutLink(false);
      }
    }

    getLandingPage();
  }, [
    saveClickedCount,
    selectedProphoneContact,
    globalStateAtom?.selected_plan,
    globalStateAtom?.prophone_contact_id,
    globalStateAtom?.salesloft_id,
  ]);

  async function getPlansFromStripe() {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/stripe-get-all-plans`
    );
    const starterPlans = response.data.message.starter.data;
    const corePlans = response.data.message.core.data;
    const proPlans = response.data.message.pro.data;

    const allPlans: any = {};
    allPlans["starter"] = starterPlans;
    allPlans["core"] = corePlans;
    allPlans["pro"] = proPlans;

    setStripePlans(allPlans);
  }

  const planTypes = ["starter", "core", "pro"];
  const defaultPlans = [
    "starter-monthly-199",
    "core-monthly-249",
    "pro-monthly-399",
    "starter-monthly-99",
    "core-monthly-149",
    "pro-monthly-249",
  ];

  let formattedPlans: any[] = ["Please select a plan", ...defaultPlans];
  planTypes?.forEach((plan: string) => {
    const plans = stripePlans[plan];
    for (let i = 0; i <= plans?.length; i++) {
      const p = plans[i];
      const metadata = p?.metadata?.plan;

      let interval = p?.recurring?.interval;
      if (interval === "month") {
        interval = "monthly";
      } else if (interval === "annual" || metadata?.includes("annual")) {
        interval = "annual";
      } else {
        continue;
      }
      const amount = p.unit_amount / 100;
      const planText = `${plan}-${interval}-${amount}`;

      if (formattedPlans.includes(planText)) {
        continue;
      }

      formattedPlans.push(planText);
    }
  });

  formattedPlans = getSortedPlans(formattedPlans);

  useEffect(() => {
    const getProphoneContacts = async () => {
      try {
        const contactResponse = (await axios(
          `${process.env.REACT_APP_SERVER_URL}/api/getProphoneContacts`
        )) as any;
        console.log("contactResponse", contactResponse);
        console.log(
          "contactResponse.body.message",
          contactResponse.data.message
        );
        setProPhoneContacts(contactResponse.data.message);
        return contactResponse.data.message;
      } catch (e) {
        console.log("error phone contacs", e);
        setProPhoneContacts([]);
        return [];
      }
    };
    getProphoneContacts();
  }, []);

  function getSortedPlans(formattedPlans: any[]) {
    const plans = [
      ...formattedPlans.slice(0, 7),
      ...formattedPlans.slice(7).sort((a, b) => {
        const aPlanType = a.split("-")[0];
        const bPlanType = b.split("-")[0];
        const order = ["starter", "core", "pro"];
        const typeComparison =
          order.indexOf(aPlanType) - order.indexOf(bPlanType);
        if (typeComparison === 0) {
          return a.localeCompare(b);
        }
        return typeComparison;
      }),
    ];
    return plans;
  }

  return (
    <>
      <div className="flex flex-col justify-start items-start bg-white p-6 rounded-lg">
        <div className="text-h1 font-bold">Checkout Link</div>

        <label className={`${label} pb-1 w-full`}>
          Plan
          <select
            onChange={(e) => {
              setGlobalState({
                ...globalStateAtom,
                selected_plan: e.target.value,
              });
              if (e.target.value !== "Please select a plan") {
                const newCheckoutLink = `app.toplinepro.com/checkout?id=${globalStateAtom.sitePk}`;
                setCheckoutLink(newCheckoutLink);
              }
            }}
            value={globalStateAtom?.selected_plan}
            className={`${borderedInputWhite} flex py-2 pb-3`}
          >
            {formattedPlans?.map((plan) => {
              return <option value={plan}>{plan}</option>;
            })}
          </select>
        </label>

        <label className={`${label} pb-1 w-full`}>
          Contact
          <select
            onChange={(e) => {
              setSelectedProphoneContact(e.target.value);
              setGlobalState({
                ...globalStateAtom,
                prophone_contact_id: e.target.value,
              });
            }}
            value={selectedProphoneContact}
            className={`${borderedInputWhite} flex py-2 pb-3`}
          >
            <option className="hidden" selected>
              Please Select Contact
            </option>
            {proPhoneContacts &&
              proPhoneContacts.length > 0 &&
              proPhoneContacts.map((contact: any) => {
                return (
                  <option value={contact.display_id}>
                    {contact.display_name}
                  </option>
                );
              })}
          </select>
        </label>

        <label className={`${label} pb-1 w-full`}>
          Salesloft ID
          <input
            className={`${borderedInputWhite} flex py-2 pb-3`}
            type="number"
            onChange={(e) =>
              setGlobalState({
                ...globalStateAtom,
                salesloft_id: e.target.value,
              })
            }
            value={globalStateAtom?.salesloft_id}
          />
        </label>

        <label className={`${label} pb-1 w-full flex flex-col`}>
          Checkout Link
          <div
            className={`${showCheckoutLink ? "text-gray-900" : "text-red-600"}`}
          >
            {showCheckoutLink
              ? checkoutLink
              : 'Add a Contact and Paid URL, select a plan, and enter Salesloft ID. Then click "Save"'}
          </div>
        </label>
        <div className="flex justify-start">
          {showCheckoutLink && <button
            className={`${buttonWhiteSmall}`}
            onClick={() => {
              navigator.clipboard.writeText(checkoutLink);
              return;
            }}
          >
            copy
          </button>}
        </div>
      </div>
    </>
  );
};

export default CheckoutLink;
