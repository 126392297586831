/* eslint-disable */
import globalState from "../../../store";
import Props from "../../../store/props";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import CustomLayoutRadio from "./CustomLayoutRadio";
import { getDefaultCustomLayout, pages, sections } from "../utils";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";

interface CustomLayoutProps {
  setCustomLayout: any;
}

const CustomLayout: React.FC<CustomLayoutProps> = ({ setCustomLayout }) => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const [selectedButton, setSelectedButton] = useState<string>("");
  const [currentSection, setCurrentSection] = useState<any>();
  const [customLayoutPreset, setCustomLayoutPreset] = useState<any>({});
  const [initialGlobalState, setInitialGlobalState] = useState<any>({
    ...globalStateAtom,
  });

  const getDefaultLayout = async () => {
    const { preset_name, ...layout } = await getDefaultCustomLayout();
    setCustomLayoutPreset(layout);
  };

  useEffect(() => {
    getDefaultLayout();
  }, []);

  useEffect(() => {
    if (globalStateAtom?.custom_layout) {
      const customLayout = globalStateAtom?.custom_layout as any;

      const filteredSectionProperties = sections.map((section) => {
        if (section.section in globalStateAtom?.custom_layout) {
          section.order = customLayout[section.section].order;
          section.version = customLayout[section.section].version;
        }
        if (section.section === "custom-page") {
          if (
            globalStateAtom?.custom_page?.custom_page &&
            customLayout[section.section]
          ) {
            section.version = customLayout[section.section].version;
          } else if (globalStateAtom?.custom_page?.custom_page) {
            section.version = "v2";
          } else {
            section.version = "none";
          }
        }
        return section;
      });
      const sortedSections = filteredSectionProperties.sort(
        (a, b) => a.order - b.order
      );

      setCurrentSection([...sortedSections]);
    } else {
      const sortedSections = sections.sort((a, b) => a.order - b.order);
      setCurrentSection([...sortedSections]);
    }
  }, [globalStateAtom?.custom_layout]);

  const onDragEnd = ({ source, destination }: DropResult) => {
    console.log(source, destination);
    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null;

    // Make sure we're actually moving the item
    if (
      destination.index === source.index &&
      destination.droppableId === source.droppableId
    )
      return null;

    const newList = [...currentSection].filter(
      (_: any, idx: number) => idx !== source.index
    );

    // Then insert the item at the right location
    newList.splice(destination.index, 0, currentSection[source.index]);
    // Update the list
    const newOrderedList = newList.map((item, index) => {
      item.order = index;
      return item;
    });
    console.log(newOrderedList);
    setCurrentSection([...newOrderedList]);
    setCustomLayout([...newOrderedList]);
    const allComponentsObject: any = {};
    newOrderedList.forEach((section, index) => {
      allComponentsObject[section.section] = {
        version: section.version,
        order: section.order,
      };
    });
    setGlobalState({
      ...globalStateAtom,
      custom_layout: { ...allComponentsObject },
    });
  };

  function selectAll(version: string) {
    const customLayout = globalStateAtom?.custom_layout as any;
    const newCustomLayout = {} as any;

    for (const section in customLayout) {
      // delete the if once we've added v3 pages
      newCustomLayout[section] = { ...customLayout[section], version };
    }

    let newGlobalState = {
      ...globalStateAtom,
      custom_layout: newCustomLayout,
    };

    if (version === "v3") {
      newGlobalState = {
        ...newGlobalState,
        custom_hero_bottom_curve_type: "ripped-edge",
        custom_service_card_background_transparency: "100",
        custom_service_card_grayscale: false,
      };
    } else {
      newGlobalState = {
        ...newGlobalState,
        custom_hero_bottom_curve_type:
          initialGlobalState?.custom_hero_bottom_curve_type,
        custom_service_card_background_transparency:
          initialGlobalState?.custom_service_card_background_transparency,
        custom_service_card_grayscale:
          initialGlobalState?.custom_service_card_grayscale,
      };
    }

    setGlobalState(newGlobalState);
    setCustomLayout(newCustomLayout);
  }

  function setUsingPreset() {
    const customLayout = globalStateAtom?.custom_layout as any;
    const newCustomLayout = {} as any;

    for (const section in customLayout) {
      newCustomLayout[section] = {
        ...customLayout[section],
        version: customLayoutPreset[section],
      };
    }

    setGlobalState({
      ...globalStateAtom,
      custom_layout: newCustomLayout,
    });
    setCustomLayout(newCustomLayout);
  }

  function selectPreset(version: string) {
    if (version === "Custom") {
      setUsingPreset();
    } else {
      selectAll(version);
      setSelectedButton(version);
    }
  }

  const presetVersions = ["v2", "v2.1", "v3", "Custom"];

  return (
    <div className="flex flex-col">
      <h2 className="text-left ml-16 -mb-6">Custom Layout</h2>

      <div className={`bg-white m-12 rounded-lg p-6 flex flex-col w-full`}>
        <div className="flex mb-4 items-center">
          <div className="mr-4">Select all?</div>
          {presetVersions?.map((version: string) => {
            return (
              <button
                className={`w-16 py-1.5 ${
                  selectedButton === version
                    ? "bg-blue-800 text-white border border-transparent"
                    : "bg-white text-gray-500 hover:bg-gray-50 border border-gray-300"
                } mr-2 text-white rounded-lg`}
                onClick={() => selectPreset(version)}
              >
                {version}
              </button>
            );
          })}
        </div>

        <div className="flex-col sm:flex-row">
          <div className="flex flex-col items-start mr-12">
            <div className="text-lg font-bold mb-4">Home Page</div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="list">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {currentSection &&
                      currentSection.map((section, index) =>
                        !section.section.includes("-page") ? (
                          <Draggable draggableId={`${index}`} index={index}>
                            {(provided) => (
                              <div
                                key={`${section?.section}`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <CustomLayoutRadio
                                  label={section.label}
                                  dataField={section.section}
                                  setCustomLayout={setCustomLayout}
                                  v2ImageSource={section.v2ImageSource}
                                  v21ImageSource={section.v21ImageSource}
                                  v22ImageSource={section.v22ImageSource}
                                />
                              </div>
                            )}
                          </Draggable>
                        ) : (
                          <></>
                        )
                      )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div className="flex flex-col items-start mr-12">
            <div className="text-lg font-bold mb-4">Other Pages</div>
            {pages.map((page) => (
              <CustomLayoutRadio
                label={page.label}
                dataField={page.section}
                setCustomLayout={setCustomLayout}
                v2ImageSource={page.v2ImageSource}
                v21ImageSource={page.v21ImageSource}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomLayout;
