/* eslint-disable */
import React, { useState } from "react";
import { useAtom } from "jotai";
import { v4 as uuidv4 } from "uuid";
import globalState from "../../../store";
import { extractQuestionAndAnswer, getCompanyFaqs } from "../utils";

interface ItemProps {
  answer: string;
  "landing-page": number;
  order: number;
  question: string;
  draggableId: string;
}

const GenerateFaqs = () => {
  const [loading, setLoading] = useState(false);
  const [globalStateAtom, setGlobalStateAtom] = useAtom(globalState);
  const { faqs, sitePk } = globalStateAtom;

  const onGenerateClickHandler = async () => {
    try {
      const { company_type, company_name } = globalStateAtom;
      setLoading(true);
      const response = await getCompanyFaqs(company_name, company_type);
      const questionAnswerObject = extractQuestionAndAnswer(response);
      let blankServiceObject: ItemProps = {
        answer: "",
        "landing-page": sitePk,
        order: faqs && faqs.length ? faqs.length : 0,
        question: "",
        draggableId: uuidv4(),
      };
      blankServiceObject = {
        ...blankServiceObject,
        ...questionAnswerObject,
      };
      setGlobalStateAtom({
        ...globalStateAtom,
        faqs: [...globalStateAtom.faqs, blankServiceObject] as any,
      });
      setLoading(false);
    } catch (error) {
      console.log("error ", error);
    }
  };
  return (
    <>
      <div onClick={onGenerateClickHandler}>
        {loading ? (
          <img src="/loading.gif" width="20" alt="loading" />
        ) : (
          <p className="cursor-pointer">Generate</p>
        )}
      </div>
    </>
  );
};

export default GenerateFaqs;
