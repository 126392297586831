import { useAtom } from "jotai";
import { IoAddOutline, IoRemoveOutline, IoCloseCircle } from "react-icons/io5";
import globalState from "../../../store";
import { ICustomBadge } from "../../../types/types";
import UploadFile from "../../services/components/upload/UploadFile";
import { useState } from "react";
import { pushImageToS3 } from "../../../utils/helpers/uploadFileUtil";

const CustomAttributes: React.FC = () => {
  const [globalStateAtom, setGlobalStateAtom] = useAtom(globalState);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingIndex, setLoadingIndex] = useState<number | null>(null);

  const { custom_badges } = globalStateAtom;

  const handleChangeShow = (badge: ICustomBadge, index: number) => {
    const newCustomBadges = [...custom_badges];
    newCustomBadges[index].show = !badge.show;
    setGlobalStateAtom({
      ...globalStateAtom,
      custom_badges: newCustomBadges,
    });
  };

  const handleChangeName = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newCustomBadges = [...custom_badges];
    newCustomBadges[index].name = e.target.value;
    setGlobalStateAtom({
      ...globalStateAtom,
      custom_badges: newCustomBadges,
    });
  };

  const handleDelete = (index: number) => {
    const newCustomBadges = custom_badges.filter(
      (badge, count) => count !== index
    );
    setGlobalStateAtom({
      ...globalStateAtom,
      custom_badges: newCustomBadges,
    });
  };

  const handleAdd = () => {
    const newBlankBadge: ICustomBadge = {
      name: "",
      icon: "",
      show: true,
    };
    setGlobalStateAtom({
      ...globalStateAtom,
      custom_badges: [...custom_badges, newBlankBadge],
    });
  };

  const handleDropFileInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      handleChangeFileInput(files, index);
    }
  };

  const handleChangeFileInput = async (files: FileList, index: number) => {
    setLoading(true);
    setLoadingIndex(index);
    const file = files[0];
    console.log(file);
    const [pushedImageUrl] = await pushImageToS3(file);
    if (pushedImageUrl) {
      const newCustomBadges = [...custom_badges];
      newCustomBadges[index].icon = pushedImageUrl;
      setGlobalStateAtom({
        ...globalStateAtom,
        custom_badges: newCustomBadges,
      });
    }
    setLoadingIndex(null);
    setLoading(false);
  };

  const handleDeleteIcon = (index: number) => {
    const newCustomBadges = [...custom_badges];
    newCustomBadges[index].icon = "";
    setGlobalStateAtom({
      ...globalStateAtom,
      custom_badges: newCustomBadges,
    });
  };

  return (
    <div className="text-left">
      <h2 className="text-left mt-4">Custom Attributes</h2>
      <div className="space-y-2">
        {custom_badges?.length &&
          custom_badges?.map((badge, index) => (
            <div key={index} className="flex items-center border-b py-2">
              <button
                disabled={loading}
                className={
                  "border rounded-full" +
                  (loading
                    ? " opacity-25 cursor-not-allowed bg-gray-300"
                    : " hover:bg-red-300 cursor-pointer")
                }
                onClick={() => {
                  const confirmation = prompt(
                    "Are you sure you want to delete this icon?",
                    "yes"
                  );
                  if (confirmation) handleDelete(index);
                }}
              >
                <IoRemoveOutline size={25} />
              </button>
              <div className="flex items-center ml-6 gap-2 w-full relative">
                <input
                  type="checkbox"
                  checked={badge.show}
                  onChange={() => handleChangeShow(badge, index)}
                />
                <input
                  className="border rounded-md px-2"
                  type="text"
                  value={badge.name}
                  onChange={(e) => handleChangeName(e, index)}
                />
                <div className="w-full h-16">
                  <UploadFile
                    onDropHandler={(e) => handleDropFileInput(e, index)}
                    onChangeHandler={(files) =>
                      handleChangeFileInput(files, index)
                    }
                    accept=".svg"
                    title="Upload .svg"
                  />
                </div>
                <div
                  className={
                    "ml-4 p-4 w-32 flex justify-center items-center" +
                    (!loading &&
                      !badge.icon &&
                      " border border-dashed h-12 rounded-md")
                  }
                >
                  {loading && loadingIndex === index ? (
                    <img src="/loading.gif" width="25px" />
                  ) : (
                    badge.icon && (
                      <img src={badge.icon} alt="icon" className="h-12" />
                    )
                  )}
                </div>
                {badge.icon && (
                  <button
                    onClick={() => {
                      const confirmation = prompt(
                        "Are you sure you want to delete this icon?",
                        "yes"
                      );
                      if (confirmation) handleDeleteIcon(index);
                    }}
                  >
                    <IoCloseCircle
                      size={20}
                      className="absolute right-0 top-0 text-red-300 hover:text-red-500 cursor-pointer"
                    />
                  </button>
                )}
              </div>
            </div>
          ))}
      </div>
      <div className="flex items-center">
        <button
          className={
            "mt-2 border rounded-full" +
            (loading
              ? " opacity-25 cursor-not-allowed bg-gray-300"
              : " hover:bg-green-300 cursor-pointer")
          }
          disabled={loading}
          onClick={handleAdd}
        >
          <IoAddOutline size={30} />
        </button>
        <span className="ml-8 mt-2.5 text-mono text-gray-400 opacity-90">
          Add new custom attribute
        </span>
      </div>
    </div>
  );
};

export default CustomAttributes;
