/* eslint-disable */
import globalState from "../../../store";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { getDefaultCustomCSS } from "../utils";

interface CustomBackGroundTransparencyAndTextColorProps {
  backgroundTransparencyComponentArr: any;
}

const CustomBackGroundTransparencyAndTextColor: React.FC<
  CustomBackGroundTransparencyAndTextColorProps
> = ({ backgroundTransparencyComponentArr }) => {
  const [globalStateAtom, setGlobalStateAtom] = useAtom(globalState);

  const {
    custom_service_card_grayscale,
    custom_hero_slider,
    custom_navigation_transparent,
    custom_hero_bottom_curve,
  } = globalStateAtom;

  const [customCSSPreset, setCustomCSSPreset] = useState<any>({});

  const getDefaultCSSOptions = async () => {
    const { id, preset_name, ...preset } = await getDefaultCustomCSS();
    setCustomCSSPreset(preset);
  };

  useEffect(() => {
    getDefaultCSSOptions();
  }, []);

  const setUsingPreset = () =>  {
    if (customCSSPreset) {
      setGlobalStateAtom({
        ...globalStateAtom,
        ...customCSSPreset,
      });
    }
  }

  useEffect(() => {
    console.log("what is globalStateAtom_____", globalStateAtom);
  });

  async function handleBackgroundTransparencyOnChange(e: any, component: any) {
    console.log("is being clicked", e.target.value);
    const newValue = e.target.value;
    setGlobalStateAtom({
      ...globalStateAtom,
      [`custom_${component}_background_transparency`]: newValue,
    });
  }

  async function handleTextColorOnChange(e: any, component: any) {
    console.log(" text color change is being clicked", e.target.value);
    const newValue = e.target.value;
    setGlobalStateAtom({
      ...globalStateAtom,
      [`custom_${component}_text_color`]: newValue,
    });
  }

  async function handleBackgroundColorOnChange(e: any, component: any) {
    console.log(" background color change is being clicked", e.target.value);
    const newValue = e.target.value;
    setGlobalStateAtom({
      ...globalStateAtom,
      [`custom_${component}_background_color`]: newValue,
    });
  }

  const handleServiceCardGrayscale = () => {
    setGlobalStateAtom({
      ...globalStateAtom,
      custom_service_card_grayscale: !custom_service_card_grayscale,
    });
  };

  function customBackgroundColor(component) {
    return (
      <div className="flex flex-col w-full">
        <div className="mt-5 mb-3 flex justify-start">
          Custom Background Color
        </div>
        <div className="flex flex-col w-full justify-between">
          <label className="flex items-center">
            <input
              checked={
                globalStateAtom?.[`custom_${component}_background_color`] ===
                  "bg-gray-900" && true
              }
              type="radio"
              name={`custom-${component}-background-color`}
              value="bg-gray-900"
              onChange={(e) => handleBackgroundColorOnChange(e, component)}
            />
            <p className="ml-2">gray 900</p>
          </label>

          <label className="flex items-center">
            <input
              checked={
                globalStateAtom?.[`custom_${component}_background_color`] ===
                  "bg-white" && true
              }
              type="radio"
              name={`custom-${component}-background-color`}
              value="bg-white"
              onChange={(e) => handleBackgroundColorOnChange(e, component)}
            />
            <p className="ml-2">white</p>
          </label>
          <label className="flex items-center">
            <input
              checked={
                globalStateAtom?.[`custom_${component}_background_color`] ===
                  "bg-primary" && true
              }
              type="radio"
              name={`custom-${component}-background-color`}
              value="bg-primary"
              onChange={(e) => handleBackgroundColorOnChange(e, component)}
            />
            <p className="ml-2">primary color</p>
          </label>
        </div>
      </div>
    );
  }

  function customHeroBackground(component) {
    return (
      <>
        <div className="w-2/5  2xl:w-1/3">
          <div className="mt-5 mb-3 flex justify-start">
            Custom Background Color
          </div>
          <div className="flex flex-col w-full justify-between">
            <label className="flex items-center">
              <input
                checked={
                  globalStateAtom?.[`custom_${component}_background_color`] ===
                    "bg-black" && true
                }
                type="radio"
                name={`custom-${component}-background-color`}
                value="bg-black"
                onChange={(e) => handleBackgroundColorOnChange(e, component)}
              />
              <p className="ml-2">black</p>
            </label>
            <label className="flex items-center">
              <input
                checked={
                  globalStateAtom?.[`custom_${component}_background_color`] ===
                    "bg-primary" && true
                }
                type="radio"
                name={`custom-${component}-background-color`}
                value="bg-primary"
                onChange={(e) => handleBackgroundColorOnChange(e, component)}
              />
              <p className="ml-2">primary color</p>
            </label>
          </div>
        </div>
        <div className="w-2/5  2xl:w-1/3">
          <label className="flex items-center">
            <input
              checked={globalStateAtom?.custom_hero_bottom_curve}
              type="checkbox"
              name={"custom-hero-bottom-curve"}
              onChange={() =>
                setGlobalStateAtom({
                  ...globalStateAtom,
                  custom_hero_bottom_curve: !custom_hero_bottom_curve,
                })
              }
            />
            <p className="ml-2">Bottom curve</p>
          </label>
          <div className="mt-2 flex justify-start">Curve Type</div>
          <div className="flex flex-col w-full justify-between">
            <label className="flex items-center">
              <input
                checked={
                  globalStateAtom?.custom_hero_bottom_curve_type ===
                    "ellipse" && true
                }
                type="radio"
                name="custom_hero_bottom_curve_type"
                value="ellipse"
                onChange={(e) => {
                  setGlobalStateAtom({
                    ...globalStateAtom,
                    custom_hero_bottom_curve_type: e.target.value,
                  });
                }}
              />
              <p className="ml-2">ellipse</p>
            </label>
            <label className="flex items-center">
              <input
                checked={
                  globalStateAtom?.custom_hero_bottom_curve_type === "wave" &&
                  true
                }
                type="radio"
                name="custom_hero_bottom_curve_type"
                value="wave"
                onChange={(e) => {
                  setGlobalStateAtom({
                    ...globalStateAtom,
                    custom_hero_bottom_curve_type: e.target.value,
                  });
                }}
              />
              <p className="ml-2">wave</p>
            </label>
            <label className="flex items-center">
              <input
                checked={
                  globalStateAtom?.custom_hero_bottom_curve_type === "big-wave" &&
                  true
                }
                type="radio"
                name="custom_hero_bottom_curve_type"
                value="big-wave"
                onChange={(e) => {
                  setGlobalStateAtom({
                    ...globalStateAtom,
                    custom_hero_bottom_curve_type: e.target.value,
                  });
                }}
              />
              <p className="ml-2">big-wave</p>
            </label>
            <label className="flex items-center">
              <input
                checked={
                  globalStateAtom?.custom_hero_bottom_curve_type === "ripped-edge" &&
                  true
                }
                type="radio"
                name="custom_hero_bottom_curve_type"
                value="ripped-edge"
                onChange={(e) => {
                  setGlobalStateAtom({
                    ...globalStateAtom,
                    custom_hero_bottom_curve_type: e.target.value,
                  });
                }}
              />
              <p className="ml-2">ripped-edge</p>
            </label>
          </div>
        </div>
      </>
    );
  }

  function customHeroBackgroundSlider() {
    return (
      <div className="flex flex-col justify-between self-center ml-4 2xl:w-1/3 2xl:ml-0">
        <label className="flex items-center">
          <input
            checked={globalStateAtom?.["custom_hero_slider"]}
            type="checkbox"
            name={"custom-hero-slider"}
            onChange={() =>
              setGlobalStateAtom({
                ...globalStateAtom,
                custom_hero_slider: !custom_hero_slider,
              })
            }
          />
          <p className="ml-2">Custom Hero Slider</p>
        </label>
        <div className="mt-2 flex justify-start">Slider Type</div>
        <div className="flex flex-col w-full justify-between">
          <label className="flex items-center">
            <input
              checked={
                globalStateAtom?.custom_hero_slider_type === "slide" && true
              }
              type="radio"
              name="custom_hero_slider_type"
              value="slide"
              onChange={(e) => {
                setGlobalStateAtom({
                  ...globalStateAtom,
                  custom_hero_slider_type: e.target.value,
                });
              }}
            />
            <p className="ml-2">slide</p>
          </label>
          <label className="flex items-center">
            <input
              checked={
                globalStateAtom?.custom_hero_slider_type === "fade" && true
              }
              type="radio"
              name="custom_hero_slider_type"
              value="fade"
              onChange={(e) => {
                setGlobalStateAtom({
                  ...globalStateAtom,
                  custom_hero_slider_type: e.target.value,
                });
              }}
            />
            <p className="ml-2">fade</p>
          </label>
        </div>
      </div>
    );
  }

  return (
    <div className="w-1/2 ml-4 mr-28">
      <h2 className="text-left ml-16 -mb-6">
        Custom Background Transparency and Text Color for V2.1
      </h2>

      <div className="bg-white m-12 rounded-lg p-6 flex-col w-full">
        <button
          className="bg-blue-800 py-1.5 hover:bg-blue-700 text-white rounded-lg px-4 justify-self-start"
          onClick={setUsingPreset}
        >
          Preset
        </button>
        {backgroundTransparencyComponentArr &&
          backgroundTransparencyComponentArr.map((component) => (
            <>
              <h1 className="text-lg font-bold mb-4 flex justify-start">
                {component.replaceAll("_", " ")}
              </h1>
              {component === "hero" && (
                <div className="flex items-center mb-4 flex-wrap gap-8 2xl:gap-0">
                  {customHeroBackground(component)}
                  {customHeroBackgroundSlider()}
                </div>
              )}
              <div className="flex mb-2 border-b-2">
                {component === "navigation" && (
                  <div className="flex flex-col w-full space-y-4 pb-4">
                    {customBackgroundColor(component)}
                    <label className="flex items-center">
                      <input
                        checked={
                          globalStateAtom?.[`custom_${component}_transparent`]
                        }
                        type="checkbox"
                        name={`custom-${component}-transparent`}
                        onChange={() => {
                          setGlobalStateAtom({
                            ...globalStateAtom,
                            custom_navigation_transparent:
                              !custom_navigation_transparent,
                          });
                        }}
                      />
                      <p className="ml-2">Transparent background</p>
                    </label>
                  </div>
                )}
                {component !== "navigation" && (
                  <div className=" flex flex-col w-full">
                    <div className="mt-5 mb-3 flex justify-start">
                      Background transparency
                    </div>
                    <div className="flex flex-col w-1/2">
                      <label className="flex flex-col items-center">
                        <p>
                          {
                            globalStateAtom?.[
                              `custom_${component}_background_transparency`
                            ]
                          }
                        </p>
                        <input
                          className="my-2"
                          type="range"
                          name={`custom-${component}-background-transparency`}
                          value={
                            globalStateAtom?.[
                              `custom_${component}_background_transparency`
                            ]
                          }
                          min={0}
                          max={100}
                          onChange={(e) =>
                            handleBackgroundTransparencyOnChange(e, component)
                          }
                        />
                      </label>
                    </div>
                  </div>
                )}

                {component === "service_card" ? (
                  <div className="flex flex-col w-1/2 justify-between self-center">
                    <label className="flex items-center">
                      <input
                        checked={
                          globalStateAtom?.[`custom_${component}_grayscale`]
                        }
                        type="checkbox"
                        name={`custom-${component}-text-color`}
                        onChange={handleServiceCardGrayscale}
                      />
                      <p className="ml-2">
                        Add grayscale effect for background
                      </p>
                    </label>
                  </div>
                ) : (
                  <div className="w-1/2">
                    <div className="mt-5 mb-3 flex justify-start">
                      Text color on custom background
                    </div>
                    <div className="flex flex-col w-full justify-between">
                      <label className="flex items-center">
                        <input
                          checked={
                            globalStateAtom?.[
                              `custom_${component}_text_color`
                            ] === "text-gray-900" && true
                          }
                          type="radio"
                          name={`custom-${component}-text-color`}
                          value="text-gray-900"
                          onChange={(e) =>
                            handleTextColorOnChange(e, component)
                          }
                        />
                        <p className="ml-2">gray 900</p>
                      </label>

                      <label className="flex items-center">
                        <input
                          checked={
                            globalStateAtom?.[
                              `custom_${component}_text_color`
                            ] === "text-white" && true
                          }
                          type="radio"
                          name={`custom-${component}-text-color`}
                          value="text-white"
                          onChange={(e) =>
                            handleTextColorOnChange(e, component)
                          }
                        />
                        <p className="ml-2">white</p>
                      </label>
                      <label className="flex items-center">
                        <input
                          checked={
                            globalStateAtom?.[
                              `custom_${component}_text_color`
                            ] === "primary" && true
                          }
                          type="radio"
                          name={`custom-${component}-text-color`}
                          value="primary"
                          onChange={(e) =>
                            handleTextColorOnChange(e, component)
                          }
                        />
                        <p className="ml-2">primary color</p>
                      </label>
                    </div>
                  </div>
                )}
              </div>
            </>
          ))}
      </div>
    </div>
  );
};

export default CustomBackGroundTransparencyAndTextColor;
