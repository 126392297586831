/* eslint-disable */
import { useState, useEffect, useContext } from "react";
import { NavLink, useParams, useHistory } from "react-router-dom";
import axios from "axios";
import { useAtom } from "jotai";
import { IoRefreshCircleOutline } from "react-icons/io5";
import formatLandingPage, {
  getTestimonialsWithDraggableId,
} from "../utils/helpers/formatLandingPage";
import globalState from "../store";
import { AppContext } from "../App";
import {
  checkIfDuplicateSlug,
  galleryPhotosUpdated,
  heroPhotosUpdated,
} from "./utils";

const Nav: React.FC = () => {
  const { slug } = useParams<{ slug?: string }>();
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [savingStatus, setSavingStatus] = useState("");
  const history = useHistory();
  const { setSaveClickedCount, setIsSaveClicked } = useContext(AppContext);

  const getFormattedLandingPageData = async (slug: string) => {
    try {
      const fetchedDataResponse = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/getLandingPage/${slug}`
      );
      const fetchedData = fetchedDataResponse.data.message;

      const formattedData = await formatLandingPage(fetchedData);
      formattedData.service_areas.sort((a: any, b: any) => a.order - b.order);

      formattedData.service_area_array.sort((a: any, b: any) => {
        a[a.length - 1] - b[b.length - 1];
      });

      formattedData.testimonials = getTestimonialsWithDraggableId(
        formattedData.testimonials
      );

      return formattedData;
    } catch (e) {
      alert(`Error getting landing page data for slug '${slug}': ${e.message}`);
      return null;
    }
  };

  const getLandingPageModified = async (slug: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/get-landing-page-modified/${slug}`
      );
      const modified = response.data.message;

      return modified;
    } catch (e) {
      console.log("Error getting last updated at timestamp: " + e.message);
      return null;
    }
  };

  const handleUpdateGlobalStore = (formattedData: any) => {
    setGlobalState({
      ...formattedData,
    });
  };

  const fetchAllData = async () => {
    if (slug) {
      setFetching(true);
      const formattedData = await getFormattedLandingPageData(slug);
      if (formattedData) {
        handleUpdateGlobalStore(formattedData);
      }
      setFetching(false);
    }
  };

  const clearCache = async () => {
    const confirmation = prompt(
      "Clear cache? Any unsaved changed might be lost.",
      "yes"
    );
    if (confirmation) {
      localStorage.setItem("globalState", "");
      await fetchAllData();
    }
  };

  async function getCachedOrFetch() {
    if (slug) {
      setFetching(true);
      const formattedData = await getFormattedLandingPageData(slug);
      const cachedGlobalState =
        localStorage.getItem("globalState") &&
        JSON.parse(localStorage.getItem("globalState") || "");

      if (formattedData) {
        if (cachedGlobalState && cachedGlobalState.slug === slug) {
          if (
            cachedGlobalState?.modified &&
            formattedData?.modified &&
            new Date(cachedGlobalState.modified).getTime() ===
              new Date(formattedData.modified).getTime()
          ) {
            setGlobalState(cachedGlobalState);
          } else if (
            confirm(
              `The latest data for ${slug} detected. Would you like to fetch?`
            )
          ) {
            localStorage.setItem("globalState", "");
            handleUpdateGlobalStore(formattedData);
          } else {
            setGlobalState(cachedGlobalState);
          }
        } else {
          localStorage.setItem("globalState", "");
          handleUpdateGlobalStore(formattedData);
        }
      }
      setFetching(false);
    }
  }

  useEffect(() => {
    getCachedOrFetch();
  }, []);

  function getNoEmptyStringAdditionalPhoneNumberList() {
    let noEmptyStringAdditionPhoneNumberList: any = [];

    for (const number of globalStateAtom.additional_phone_number_list) {
      if (number !== "") {
        noEmptyStringAdditionPhoneNumberList = [
          ...noEmptyStringAdditionPhoneNumberList,
          number,
        ];
      }
    }

    return noEmptyStringAdditionPhoneNumberList;
  }

  const handleSave = async (ishandleSaveinHandlePublish = false) => {
    try {
      setIsSaveClicked(true);
      setLoading(true);

      const originalLandingPageData = await getFormattedLandingPageData(
        globalStateAtom?.slug
      );
      const resaveGalleryPhotos = galleryPhotosUpdated(
        originalLandingPageData,
        globalStateAtom
      );
      const resaveHeroPhotos = heroPhotosUpdated(
        originalLandingPageData,
        globalStateAtom
      );

      console.log("need to resave gallery photos:", resaveGalleryPhotos);
      console.log("need to resave hero photos:", resaveHeroPhotos);

      const newSlugIsDuplicate = await checkIfDuplicateSlug(
        globalStateAtom?.slug
      );
      if (newSlugIsDuplicate && slug !== globalStateAtom.slug) {
        setLoading(false);
        setIsSaveClicked(false);
        return alert(
          "Slug already exists in DB. Saving may overwrite existing data."
        );
      }

      if (slug !== globalStateAtom.slug) {
        const userConfirmed = confirm(
          "Are you sure you want to save this site with a different slug?"
        );
        if (!userConfirmed) {
          setLoading(false);
          setIsSaveClicked(false);
          return;
        }
      }

      if (slug == globalStateAtom.slug) {
        const modified = await getLandingPageModified(slug);
        if (
          modified &&
          globalStateAtom?.modified &&
          new Date(modified).getTime() >
            new Date(globalStateAtom.modified).getTime()
        ) {
          if (
            !confirm(
              `New changes have been detected. Do you want to overwrite them?`
            )
          ) {
            setIsSaveClicked(false);
            setLoading(false);
            return alert("Landing page data has not been saved.");
          }
        }
      }

      let handledServices;
      try {
        setSavingStatus("Saving - Service Photos");
        const handleSavingServicePhotosResponse = await axios.post(
          `${process.env.REACT_APP_NODE_SERVER_URL}/handleSavingServicePhotosEndpoint`,
          { services: globalStateAtom.services }
        );
        handledServices = handleSavingServicePhotosResponse?.data;
        globalStateAtom.services = handledServices;
      } catch (e) {
        console.log("pass");
      }

      setSavingStatus("Saving - LandingPage");
      const noEmptyStringAddiontalPhoneNumberList =
        getNoEmptyStringAdditionalPhoneNumberList();

      try {
        await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/edit/landingpage`,
          {
            ...globalStateAtom,
            services: handledServices,
            additional_phone_number_list: noEmptyStringAddiontalPhoneNumberList,
            resaveGalleryPhotos,
            resaveHeroPhotos,
          }
        );
      } catch (e) {
        console.log("pass");
      }

      const tmpPhotos = globalStateAtom?.gallery as any[];
      const heroPhotos = globalStateAtom?.hero_gallery_photos as any[];
      const photos = tmpPhotos;

      try {
        if (resaveGalleryPhotos) {
          setSavingStatus("Saving - Gallery Photos");
          const handleSavingResponse = await axios.post(
            `${process.env.REACT_APP_NODE_SERVER_URL}/handleSavingPhotosEndpoint`,
            { pk: globalStateAtom.sitePk, photos }
          );
          if (handleSavingResponse?.data?.status === "Success") {
            globalStateAtom.gallery = handleSavingResponse.data.photos;
          }
        }
      } catch (e) {
        console.log("pass");
      }

      try {
        if (resaveHeroPhotos) {
          setSavingStatus("Saving - Hero Photos");
          const handleSavingHeroPhotosResponse = await axios.post(
            `${process.env.REACT_APP_NODE_SERVER_URL}/handleSavingPhotosEndpoint`,
            {
              heroPhotos: true,
              pk: globalStateAtom.sitePk,
              photos: heroPhotos,
            }
          );
          if (handleSavingHeroPhotosResponse?.data?.status === "Success") {
            globalStateAtom.hero_gallery_photos =
              handleSavingHeroPhotosResponse.data.photos;
          }
        }
      } catch (e) {
        console.log("pass");
      }

      const localStorageData = localStorage.getItem("globalState") || "";
      const oldCache = JSON.parse(localStorageData) || ({} as any);
      const newCache = oldCache as any;

      const newLandingPageCacheItem = {
        ...globalStateAtom,
      };

      newCache[globalStateAtom.slug] = newLandingPageCacheItem;
      localStorage.setItem("globalState", JSON.stringify(newCache));

      setGlobalState(globalStateAtom);
      !ishandleSaveinHandlePublish ? alert(`Saved successfully`) : null;

      if (slug !== globalStateAtom.slug) {
        history.push(globalStateAtom.slug);
        setSavingStatus("");
        setLoading(false);
        return;
      }

      await fetchAllData();

      setSavingStatus("");
      setLoading(false);

      try {
        setSaveClickedCount((count) => count + 1);
      } catch (e) {
        try {
          setSaveClickedCount(1);
        } catch (e) {
          setSaveClickedCount(0);
        }
      }
    } catch (e) {
      const errorResponse = e as any;
      const errorMessage = errorResponse?.response?.data?.message;
      alert(
        `There was a problem saving. Nick and Shannon broke it. Here is how:  ${
          errorMessage || errorResponse
        }`
      );
      setSavingStatus("");
      setLoading(false);
    }
  };

  const handlePublish = async () => {
    const { multipage, version } = globalStateAtom;
    if (!multipage && version === "vC") {
      alert(
        'Error: publishing is blocked.\nNot allowed to publich "Single-page vC site".\nPlease, select "Multi-page site" or change the site version.'
      );
      setLoading(false);
      return;
    }

    try {
      await handleSave(true);

      setLoading(true);

      setSavingStatus("Publishing...");

      const { github_repo, paid_url, id_key, slug, multipage, version } =
        globalStateAtom;
      if (!multipage && version === "vC") {
        alert(
          'Error: publishing is blocked.\nNot allowed to publich "Single-page vC site".\nPlease, select "Multi-page site" or change the site version.'
        );
        setLoading(false);
        return;
      }
      const githubData = {
        github_repo, // paid_url,
        paid_url,
        id_key,
        slug,
        first_publish_url: github_repo ? "" : paid_url, // first_publish_url Need this value to publish for first time
      };
      const postResponse = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/${multipage ? "m/" : ""}publish`,
        githubData
      );

      const message =
        postResponse && postResponse.data ? postResponse.data.message : "";
      // console.log("message", message);
      // if (githubData.first_publish_url) await sendWebhook();

      setGlobalState({
        ...globalStateAtom,
        github_repo: githubData.github_repo
          ? githubData.github_repo
          : githubData.first_publish_url,
      });

      await setSavingStatus("Finished Publishing...");

      setLoading(false);
      alert("Saved and published sucessfully");
    } catch (e) {
      const errorResponse = e as any;
      const errorMessage = errorResponse?.response?.data?.message;
      // console.log(e, "saving error");
      alert(
        `There was a problem publishing. Nick and Shannon broke it. Here is how:  ${
          errorMessage || errorResponse
        }`
      );
      setSavingStatus("");
      setLoading(false);
    }
  };

  function isValidUrl(string: string) {
    const regex =
      /^(?!www)(?!.*\s)(?!.*http)(?!.*https)[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
    return regex.test(string);
  }

  function isPublishDisabled() {
    const paidUrl = globalStateAtom?.paid_url;
    const githubRepo = globalStateAtom?.github_repo;
    const prophoneContactId = globalStateAtom?.prophone_contact_id;

    if (!paidUrl) {
      return true;
    }
    if (!isValidUrl(paidUrl)) {
      return true;
    }
    if (paidUrl?.includes("/")) {
      return true;
    }
    if (!githubRepo) {
      return true;
    }
    if (!isValidUrl(githubRepo)) {
      return true;
    }
    if (githubRepo?.includes("/")) {
      return true;
    }
    if (!prophoneContactId) {
      return true;
    }

    return false;
  }

  function isSaveDisabled() {
    const paidUrl = globalStateAtom?.paid_url;
    const githubRepo = globalStateAtom?.github_repo;
    const prophoneContactId = globalStateAtom?.prophone_contact_id;

    if (paidUrl && !isValidUrl(paidUrl)) {
      return true;
    }
    if (paidUrl && paidUrl?.includes("/")) {
      return true;
    }
    if (githubRepo && !isValidUrl(githubRepo)) {
      return true;
    }
    if (githubRepo && githubRepo?.includes("/")) {
      return true;
    }
    if (!prophoneContactId) {
      return true;
    }

    return false;
  }

  return loading ? (
    <div className="flex justify-center py-10">
      <img src="/loading.gif" width="20" />
      <p>{savingStatus}</p>
    </div>
  ) : fetching ? (
    <div className="flex justify-center py-10">
      <img src="/loading.gif" width="20" />
      <p>Fetchingg</p>
    </div>
  ) : (
    <div
      className="w-full sticky top-0 bg-white flex justify-between flex-row my-6 px-6"
      style={{ zIndex: 999 }}
    >
      <ul className="w-full flex justify-around transform -translate-y-2 flex-row mt-5 text-opacity-50">
        <li className="text-gray-500">
          <NavLink activeClassName="border-b-2" to="/">
            New Site
          </NavLink>
        </li>
        <li className="text-gray-500">
          <NavLink activeClassName="border-b-2" to={`/logo/${slug}`}>
            Logo
          </NavLink>
        </li>
        <li className="text-gray-500">
          <NavLink activeClassName="border-b-2" to={`/basicInfo/${slug}`}>
            Basic Info
          </NavLink>
        </li>
        <li className="text-gray-500">
          <NavLink activeClassName="border-b-2" to={`/services/${slug}`}>
            Services
          </NavLink>
        </li>
        <li className="text-gray-500">
          <NavLink activeClassName="border-b-2" to={`/photos/${slug}`}>
            Photos
          </NavLink>
        </li>
        <li className="text-gray-500">
          <NavLink activeClassName="border-b-2" to={`/faqs/${slug}`}>
            FAQs
          </NavLink>
        </li>
        <li className="text-gray-500">
          <NavLink activeClassName="border-b-2" to={`/reviews/${slug}`}>
            Reviews
          </NavLink>
        </li>
        <li className="text-gray-500">
          <NavLink activeClassName="border-b-2" to={`/partnerships/${slug}`}>
            Partnerships
          </NavLink>
        </li>
        <li className="text-gray-500">
          <NavLink activeClassName="border-b-2" to={`/team/${slug}`}>
            Team
          </NavLink>
        </li>
        <li className="text-gray-500">
          <NavLink activeClassName="border-b-2" to={`/domain/${slug}`}>
            Domain
          </NavLink>
        </li>
        <li className="text-gray-500">
          <NavLink activeClassName="border-b-2" to={`/custom/${slug}`}>
            Custom
          </NavLink>
        </li>
        <li className="text-gray-500">
          <NavLink activeClassName="border-b-2" to={`/custom-page/${slug}`}>
            Custom Page
          </NavLink>
        </li>
        {globalStateAtom?.has_ads_landing_page && (
          <li className="text-gray-500">
            <NavLink activeClassName="border-b-2" to={`/ads-page/${slug}`}>
              Ads Page
            </NavLink>
          </li>
        )}
        {/* <li className="text-gray-500">
          <NavLink activeClassName="border-b-2" to={`/preview/${slug}`}>
            Preview
          </NavLink>
        </li> */}
      </ul>
      <div className="w-72 flex flex-col justify-around flex-row">
        <div className="flex space-x-4">
          <button
            disabled={isSaveDisabled()}
            title="save"
            onClick={handleSave}
            className={`p-3 px-6 w-24 rounded-full border-2 text-sm ${
              isSaveDisabled() ? "bg-red-200 text-white" : "bg-white text-black"
            }`}
          >
            Save
          </button>
          <button
            disabled={isPublishDisabled()}
            title="publish"
            onClick={handlePublish}
            className={`p-3 px-6 w-24 rounded-full border-2 text-white text-sm bg-gray-500 ${
              isPublishDisabled() ? "bg-red-200" : "bg-green-200"
            }`}
          >
            Publish
          </button>
        </div>
        {!globalStateAtom?.prophone_contact_id && (
          <div className="w-full bg-red-100 mt-4 px-3 rounded-full font-bold whitespace-no-wrap">
            No Contact Selected on /domain
          </div>
        )}
      </div>
      <div
        onClick={clearCache}
        className="flex fixed -right-8 top-1/4 cursor-pointer transform -rotate-90 translate-y-6 translate-x-4 rounded-sm bg-blue-500 text-white text-sm px-3 pt-2 pb-4 transition-all duration-300 ease-in-out hover:scale-y-105 hover:translate-x-3 hover:shadow-inner hover:mix-blend-darken shadow-black"
      >
        <IoRefreshCircleOutline color="white" size={18} />
        <p className="ml-1">Clear Cache</p>
      </div>
    </div>
  );
};

export default Nav;
