/* eslint-disable */
import { useAtom } from "jotai";
import {
  borderedInputWhite,
  boxContainerFull,
  label,
  labelWrap,
} from "../../../shared/styles";
import globalState from "../../../store";
import Props from "../../../store/props";

const JobsDescription: React.FC<Props> = () => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  // const { show_jobs_page, jobs_description } = globalStateAtom

  return (
    <div className="w-full flex flex-col">
      <h2 className="self-end -mr-6 -mb-6">Jobs Description</h2>
      <div className={`${boxContainerFull} ml-6`}>
        <label className="flex justify-start items-center">
          Show jobs page
          <input
            type="checkbox"
            className="ml-3"
            onChange={(e) =>
              setGlobalState({
                ...globalStateAtom,
                show_jobs_page: !globalStateAtom.show_jobs_page,
              })
            }
            checked={globalStateAtom.show_jobs_page}
          />
        </label>
        <label className={label}>
          Jobs Description
          <textarea
            value={globalStateAtom.jobs_description}
            className={borderedInputWhite}
            onChange={(e) =>
              setGlobalState({
                ...globalStateAtom,
                jobs_description: e.target.value,
              })
            }
          />
        </label>
      </div>
    </div>
  );
};

export default JobsDescription;
