import axios from "axios";

type GeonamesResponse = {
  data: {
    geonames: {
      name: string;
      adminCode1: string;
      population: number;
      lat: number;
      lng: number;
    }[];
  };
};

export async function getGeonames(city: string, state: string) {
  try {
    const params = {
      adminCode1: state,
      q: city,
      username: "jonathanrstern",
      featureClass: "P",
      continentCode: "NA",
    };

    const response: GeonamesResponse = await axios.get(
      `https://secure.geonames.org/searchJSON`,
      {
        params,
      }
    );
    return response.data.geonames;
  } catch (e) {
    console.log("error:", e);
    return [];
  }
}

export async function getGeonamesByCoordinates(
  latitude: number,
  longitude: number,
  radius: number
) {
  try {
    const response: GeonamesResponse = await axios.get(
      `https://secure.geonames.org/findNearbyPlaceNameJSON?lat=${latitude}&lng=${longitude}&radius=${radius}&maxRows=100&cities=cities15000&username=jonathanrstern`
    );
    return response.data.geonames;
  } catch (e) {
    console.log("error:", e);
    return [];
  }
}
