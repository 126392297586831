/* eslint-disable */
import { useAtom } from "jotai";
import { useState, useEffect } from "react";
import { useDebounce } from "use-debounce";
import {
  borderedInputWhite,
  boxContainer,
  label,
  button,
} from "../../../shared/styles";
import globalState from "../../../store";
import Props from "../../../store/props";
import Generate from "./Generate";
import { AppContext } from "../../../App";
import formatPhoneNumber from "../../../utils/helpers/formatPhoneNumber";
import CustomBackGroundTransparencyAndTextColor from "../../domain/components/CustomBackGroundTransparencyAndTextColor";
import AdditionalPhoneNumbersForLeadText from "./AdditionalPhoneNumbersForLeadText";
import CustomAttributes from "./CustomAttributes";


const CompanyInfo: React.FC = () => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const [firstTimeUpdatePhoneNumber, setFirstTimeUpdatePhoneNumber] =
    useState(false);
  const {
    company_name,
    company_tagline,
    company_type,
    phone,
    email,
    about,
    license_number,
    service_area_array,
    // licensed,
    insured,
    bonded,
    emergency,
    veteran,
    family,
    local,
    black_owned,
    native_owned,
    years_in_business_attribute,
    faqs,
    additional_phone_number_list,
    woman_owned,
    latino_owned,
    bbb_attribute,
  } = globalStateAtom;
  const [originalPhoneNumber, setOriginalPhoneNumber] = useState(phone);

  const [city, setCity] = useState(
    service_area_array && service_area_array[0] && service_area_array[0][0]
      ? service_area_array[0][0]
      : ""
  );
  const [state, setState] = useState(
    service_area_array && service_area_array[0] && service_area_array[0][1]
      ? service_area_array[0][1]
      : ""
  );

  const [value] = useDebounce(
    service_area_array && service_area_array[0] && service_area_array[0][0]
      ? service_area_array[0][0]
      : "",
    1000
  );
  const [geoStateValue] = useDebounce(
    service_area_array && service_area_array[0] && service_area_array[0][1]
      ? service_area_array[0][1]
      : "",
    1000
  );
  const [companyName, setCompanyName] = useState(company_name);
  const [companyNameDebounce] = useDebounce(companyName, 1000);
  const [buttonText, setButtonText] = useState(
    "Update phone number for all CTAs"
  );

  const [alert, setAlert] = useState("");
  const [emailAlert, setEmailAlert] = useState("")

  useEffect(() => {
    if (
      globalStateAtom.phone === "" &&
      globalStateAtom.cta_nav_1 === "none" &&
      globalStateAtom.cta_nav_1_link === ""
    ) {
      console.log("get to this useEffect");
      setFirstTimeUpdatePhoneNumber(true);
    }
  }, []);

  // auto-updating the state in each of the tagline and about sections when the first service area changes
  useEffect(() => {
    if (globalStateAtom.state !== "") {
      const newAbout = (
        globalStateAtom?.about && globalStateAtom.state
          ? globalStateAtom?.about.replace(globalStateAtom.state, geoStateValue)
          : globalStateAtom?.about
      ) as any;
      const newTagline = (
        globalStateAtom?.company_tagline
          ? globalStateAtom?.company_tagline.replace(
              globalStateAtom.state,
              value
            )
          : globalStateAtom.company_tagline
      ) as any;
      setGlobalState({
        ...globalStateAtom,
        company_tagline: newTagline,
        about: newAbout,
      });

      setState(geoStateValue);
    }
  }, [geoStateValue]);

  // auto-updating the city in each of the tagline and about sections when the first service area changes
  useEffect(() => {
    if (globalStateAtom.city !== "") {
      const newAbout = (
        globalStateAtom?.about
          ? globalStateAtom?.about.replace(globalStateAtom.city, value)
          : globalStateAtom?.about
      ) as any;
      const newTagline = (
        globalStateAtom?.company_tagline
          ? globalStateAtom?.company_tagline.replace(
              globalStateAtom.city,
              value
            )
          : globalStateAtom.company_tagline
      ) as any;
      setGlobalState({
        ...globalStateAtom,
        company_tagline: newTagline,
        about: newAbout,
      });

      setCity(value);
    }
  }, [value]);

  // auto-updating the company_name
  useEffect(() => {
    if (company_name !== "") {
      const newAbout = about.replace(company_name, companyNameDebounce);
      setGlobalState({
        ...globalStateAtom,
        company_name: companyNameDebounce,
        about: newAbout,
      });
    }
  }, [companyNameDebounce]);

  function handleChangePhoneNumberInNavCTA2() {
    console.log("get to handleChangeNumber");
    const phone = globalStateAtom.phone;
    let newCtaNav1 = globalStateAtom.cta_nav_1,
      newCtaNav1Link = globalStateAtom.cta_nav_1_link,
      newCallToActionMessageNav1 = globalStateAtom.call_to_action_message_nav_1,
      newCtaNavLink = globalStateAtom.cta_nav_link,
      newCtaMainLink = globalStateAtom.cta_main_link,
      newCtaMain1Link = globalStateAtom.cta_main_1_link,
      newCtaSlideInLink = globalStateAtom.cta_slide_in_link,
      newCtaRecruitingLink = globalStateAtom.cta_recruiting_link;

    if (
      globalStateAtom.cta_nav_1 === "none" ||
      globalStateAtom.cta_nav_1 === "call" ||
      globalStateAtom.cta_nav_1 === "text"
    ) {
      newCtaNav1 =
        globalStateAtom.cta_nav_1 !== "none"
          ? globalStateAtom.cta_nav_1
          : "call";
      newCtaNav1Link = phone;
      newCallToActionMessageNav1 = formatPhoneNumber(phone, "INTERNATIONAL");
    }

    if (
      globalStateAtom.cta_nav === "call" ||
      globalStateAtom.cta_nav === "text"
    ) {
      newCtaNavLink = phone;
    }
    if (
      globalStateAtom.cta_main === "call" ||
      globalStateAtom.cta_main === "text"
    ) {
      newCtaMainLink = phone;
    }
    if (
      globalStateAtom.cta_main_1 === "call" ||
      globalStateAtom.cta_main_1 === "text"
    ) {
      newCtaMain1Link = phone;
    }
    if (
      globalStateAtom.cta_slide_in === "call" ||
      globalStateAtom.cta_slide_in === "text"
    ) {
      newCtaSlideInLink = phone;
    }
    if (
      globalStateAtom.cta_recruiting === "call" ||
      globalStateAtom.cta_recruiting === "text"
    ) {
      newCtaRecruitingLink = phone;
    }
    // console.log(
    //   formatPhoneNumber(originalPhoneNumber, "DISPLAY"),
    //   "formatted original phone number in faq"
    // );
    const newFaqs = faqs.map((faq: any) => {
      // eslint-disable-next-line
      const pRegex = /((\+?1\s?)?(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4})/gi;
      const newAnswer = faq.answer.replace(
        pRegex,
        formatPhoneNumber(phone, "DISPLAY")
      );
      return { ...faq, answer: newAnswer };
      // let newFaq = faq;
      // if (
      //   faq.answer.includes(formatPhoneNumber(originalPhoneNumber, "DISPLAY"))
      // ) {
      //   console.log("inlcudes formatted number");
      //   const newAnswer = faq.answer.replace(
      //     formatPhoneNumber(originalPhoneNumber, "DISPLAY"),
      //     formatPhoneNumber(phone, "DISPLAY")
      //   );
      //   newFaq = { ...faq, answer: newAnswer };
      // }
      // if (faq.answer.includes(originalPhoneNumber)) {
      //   console.log("inlcudes original number");
      //   const newAnswer = faq.answer.replace(
      //     originalPhoneNumber,
      //     formatPhoneNumber(phone, "DISPLAY")
      //   );
      //   newFaq = { ...faq, answer: newAnswer };
      // }
      // return newFaq;
    });

    setGlobalState({
      ...globalStateAtom,
      cta_nav_1:
        globalStateAtom.cta_nav_1 !== "none"
          ? globalStateAtom.cta_nav_1
          : "call",
      cta_nav_1_link: phone,
      call_to_action_message_nav_1: formatPhoneNumber(phone, "DISPLAY"),
      cta_nav_link: newCtaNavLink,
      cta_main_link: newCtaMainLink,
      cta_main_1_link: newCtaMain1Link,
      cta_recruiting_link: newCtaRecruitingLink,
      cta_slide_in_link: newCtaSlideInLink,
      faqs: newFaqs,
    });
    setButtonText("Updated!");
  }

  function handleChangePhoneNumber(e: any) {
    let ctaNav2 = globalStateAtom.cta_nav_1;
    let ctaNav2Link = globalStateAtom.cta_nav_1_link;
    let ctaMessageNav2 = globalStateAtom.call_to_action_message_nav_1;
    if (firstTimeUpdatePhoneNumber) {
      ctaNav2 = "call";
      ctaNav2Link = e.target.value;
      ctaMessageNav2 = e.target.value;
    }
    setGlobalState({
      ...globalStateAtom,
      phone: e.target.value,
      cta_nav_1: ctaNav2,
      cta_nav_1_link: ctaNav2Link,
      call_to_action_message_nav_1: ctaMessageNav2,
    });
  }

  function handleAddPhoneNumber() {
    if (additional_phone_number_list.length === 9) {
      setAlert("Limit of 10 phone numbers");
      return;
    }
    const newPhoneNumberArr = [...additional_phone_number_list, ""];

    setGlobalState({
      ...globalStateAtom,
      additional_phone_number_list: newPhoneNumberArr,
    });
  }

  function validateEmail(email: string) {
    return email?.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

  function handleOnBlur(e: any){
    const input = e.target.value
    const isEmailValid = validateEmail(input)
      if (!isEmailValid && input){
        setEmailAlert("Please enter a valid email")
      }
  }

  return (
    <>
      <div className={boxContainer}>
        <h2>Company Info</h2>
        <label className={label}>
          Company Name
          <input
            // onChange={(e) => setCompanyName(e.target.value)}
            onChange={(e) =>
              setGlobalState({
                ...globalStateAtom,
                company_name: e.target.value,
              })
            }
            value={globalStateAtom.company_name}
            className={borderedInputWhite}
          />
        </label>
        <label className={label}>
          <div className="flex justify-between w-full">
            <p>Tagline</p>
            <Generate
              tagline={true}
            />
          </div>
          <textarea
            onChange={(e) =>
              setGlobalState({
                ...globalStateAtom,
                company_tagline: e.target.value,
              })
            }
            value={globalStateAtom.company_tagline}
            className={borderedInputWhite}
            rows={8}
          />
        </label>
        <label className={label}>
          Industry
          <input
            onChange={(e) =>
              setGlobalState({
                ...globalStateAtom,
                company_type: e.target.value,
              })
            }
            value={globalStateAtom.company_type}
            className={borderedInputWhite}
          />
        </label>
        <label className={label}>
          <div className="flex justify-between w-full">
            <div className="flex items-center">Phone Number</div>
            <button
              className={`py-2 px-4 m-3 rounded-full border-2 text-white text-sm bg-blue-900 hover:bg-blue-800`}
              onClick={handleAddPhoneNumber}
            >
              + Add Phone Number
            </button>

            <button
              className={`${button} hover:bg-gray-400`}
              onClick={handleChangePhoneNumberInNavCTA2}
            >
              {buttonText}
            </button>
          </div>
          <input
            onChange={handleChangePhoneNumber}
            value={globalStateAtom.phone}
            className={borderedInputWhite}
          />
        </label>
        <AdditionalPhoneNumbersForLeadText setAlert={setAlert} />
        <div className="text-red-600 text-sm-normal text-left">{alert}</div>
        <label className={label}>
          Email
          <input
            onChange={(e) => {
              setEmailAlert("")
              setGlobalState({
                ...globalStateAtom,
                email: e.target.value,
              })
            }
            }
            value={globalStateAtom.email}
            className={borderedInputWhite}
            onBlur={handleOnBlur}
          />
          {emailAlert && <div className="text-red-500 text-sm mt-1">{emailAlert}</div>}
        </label>
        <label className={label}>
          <div className="flex justify-between w-full">
            <p>About</p>
            <Generate
              // location={`${city}, ${state}`}
              // companyType={company_type}
              tagline={false}
            />
          </div>
          <textarea
            onChange={(e) =>
              setGlobalState({
                ...globalStateAtom,
                about: e.target.value,
              })
            }
            value={globalStateAtom.about}
            rows={10}
            className={borderedInputWhite}
          />
        </label>

        <label className={label}>Site Title</label>
        <input
          value={globalStateAtom.title_tag}
          onChange={(e) => setGlobalState({ ...globalStateAtom, title_tag: e.target.value })}
          className={borderedInputWhite}
        />
        <label className={label}>Site Description</label>
        <input
          value={globalStateAtom.meta_description}
          onChange={(e) => setGlobalState({ ...globalStateAtom, meta_description: e.target.value })}
          className={borderedInputWhite}
        />
        <label className={label}>
          License Number
          <input
            onChange={(e) =>
              setGlobalState({
                ...globalStateAtom,
                license_number: e.target.value,
              })
            }
            value={globalStateAtom.license_number}
            className={borderedInputWhite}
          />
        </label>
        <label>
          Years in Business:
          <input
            onChange={(e) =>
              setGlobalState({
                ...globalStateAtom,
                years_in_business_attribute: e.target.value,
              })
            }
            value={globalStateAtom.years_in_business_attribute}
          />
        </label>
        <label className={label}>
          Attributes
          {/* <input onChange={(e) => setGlobalState({...globalState, years: e.target.value})} value={years} /> */}
          <div className="flex flex-wrap">
            {/* <label className='mr-5'>
                    <input
                        className='mr-1'
                        name="licensed"
                        type="checkbox"
                        checked={licensed}
                        onChange={(e) => setGlobalState({...globalState, licensed: !licensed})} 
                    />
                    Licensed
                </label> */}
            <label className="mr-5">
              <input
                className="mr-1"
                name="insured"
                type="checkbox"
                checked={insured}
                onChange={(e) =>
                  setGlobalState({
                    ...globalStateAtom,
                    insured: !insured,
                  })
                }
              />
              Insured
            </label>
            <label className="mr-5">
              <input
                className="mr-1"
                name="bonded"
                type="checkbox"
                checked={bonded}
                onChange={(e) =>
                  setGlobalState({
                    ...globalStateAtom,
                    bonded: !bonded,
                  })
                }
              />
              Bonded
            </label>
            <label className="mr-5">
              <input
                className="mr-1"
                name="emergency"
                type="checkbox"
                checked={globalStateAtom.emergency}
                onChange={(e) =>
                  setGlobalState({
                    ...globalStateAtom,
                    emergency: !emergency,
                  })
                }
              />
              Emergency Services
            </label>
            <label className="mr-5">
              <input
                className="mr-1"
                name="veteran"
                type="checkbox"
                checked={globalStateAtom.veteran}
                onChange={(e) =>
                  setGlobalState({
                    ...globalStateAtom,
                    veteran: !veteran,
                  })
                }
              />
              Veteran
            </label>
            <label className="mr-5">
              <input
                className="mr-1"
                name="black_owned"
                type="checkbox"
                checked={globalStateAtom.black_owned}
                onChange={(e) =>
                  setGlobalState({
                    ...globalStateAtom,
                    black_owned: !black_owned,
                  })
                }
              />
              Black Owned
            </label>
            <label className="mr-5">
              <input
                className="mr-1"
                name="native_owned"
                type="checkbox"
                checked={globalStateAtom.native_owned}
                onChange={(e) =>
                  setGlobalState({
                    ...globalStateAtom,
                    native_owned: !native_owned,
                  })
                }
              />
              Native Owned
            </label>
            {/* 
                <input
                    name="woman_owned_business_attribute"
                    type="checkbox"
                    checked={woman_owned_attribute}
                    onChange={(e) => setGlobalState({...globalState, woman_owned_attribute: e.target.value === 'on' && true})} 
                /> */}
            <label className="mr-5">
              <input
                className="mr-1"
                name="family"
                type="checkbox"
                checked={globalStateAtom.family}
                onChange={(e) =>
                  setGlobalState({
                    ...globalStateAtom,
                    family: !family,
                  })
                }
              />
              Family Owned
            </label>
            <label className="mr-5">
              <input
                className="mr-1"
                name="local"
                type="checkbox"
                checked={globalStateAtom.local}
                onChange={(e) =>
                  setGlobalState({
                    ...globalStateAtom,
                    local: !local,
                  })
                }
              />
              Local Business
            </label>
            <label className="mr-5">
              <input
                className="mr-1"
                name="woman_owned"
                type="checkbox"
                checked={globalStateAtom.woman_owned}
                onChange={(e) =>
                  setGlobalState({
                    ...globalStateAtom,
                    woman_owned: !woman_owned,
                  })
                }
              />
              Woman Owned
            </label>
            <label className="mr-5">
              <input
                className="mr-1"
                name="latino_owned"
                type="checkbox"
                checked={globalStateAtom.latino_owned}
                onChange={(e) =>
                  setGlobalState({
                    ...globalStateAtom,
                    latino_owned: !latino_owned,
                  })
                }
              />
              Latino Owned
            </label>
            <label className="mr-5">
              <input
                className="mr-1"
                name="bbb_attribute"
                type="checkbox"
                checked={globalStateAtom.bbb_attribute}
                onChange={(e) =>
                  setGlobalState({
                    ...globalStateAtom,
                    bbb_attribute: !bbb_attribute,
                  })
                }
              />
              BBB
            </label>
          </div>
        </label>
        <CustomAttributes />
      </div>
    </>
  );
};

export default CompanyInfo;
